<template>
  <section
    v-loading="loading"
    :element-loading-text="loadingText"
    class="custom-dashboard-view"
    v-if="this.checkUrlType() == 'SignIn'"
  >
    <el-row class="row">
      <el-col
        :xs="{ span: 4 }"
        :sm="{ span: 8 }"
        :md="{ span: 2 }"
        :lg="{ span: 2 }"
        :xl="{ span: 1 }"
      >
        <el-button @click="goBack">
          <i class="el-icon-arrow-left el-icon-left">Back</i>
        </el-button>
      </el-col>
      <el-col
        :xs="{ span: 13, offset: 2 }"
        :sm="{ span: 16 }"
        :md="{ span: 8 }"
        :lg="{ span: 8 }"
        :xl="{ span: 8 }"
      >
        <el-input
          v-model="customDashboardData.name"
          placeholder="Enter dashboard name"
          class="title-input"
        ></el-input>
      </el-col>
      <el-col
        :xs="{ span: 12 }"
        :sm="{ span: 16, offset: 8 }"
        :md="{ span: 4, offset: 9 }"
        :lg="{ span: 4, offset: 9 }"
        :xl="{ span: 4, offset: 9 }"
      >
        <div style="display: flex; align-items: center">
          <span>Allow change order</span>
          <el-switch
            v-model="customDashboardData.allowUserChangeOrder"
            class="ml-1"
          ></el-switch>
        </div>
      </el-col>
      <el-col
        :xs="{ span: 1, offset: 2 }"
        :sm="{ span: 8, offset: 0 }"
        :md="{ span: 1, offset: 0 }"
        :lg="{ span: 1, offset: 0 }"
        :xl="{ span: 1, offset: 0 }"
      >
        <el-button type="primary" @click="saveDashboard">Save</el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="4" v-if="getIsOpen">
        <div class="left-side-menu">
          <el-row type="flex" justify="center">
            <el-col :xl="24" :lg="24" :md="24">
              <div class="side-menu">
                <el-scrollbar
                  wrap-style="max-height:  calc(100vh - 250px); padding-right: 10px;"
                >
                  <div>
                    <h4>Component Type</h4>
                    <el-select
                      v-model="selectedComponentType"
                      no-data-text="No components available"
                      filterable
                      default-first-option
                      class="mb-1"
                      @change="getComponentInfo"
                    >
                      <el-option
                        v-for="(type, index) of componentTypes"
                        :value="type.value"
                        :key="type.value + index"
                        :label="type.label"
                      >
                        <span style="float: left">{{ type.label }}</span>
                      </el-option>
                    </el-select>
                    <el-checkbox
                      v-if="
                        ![
                          'LOGINS',
                          'ENTITY_VIEW',
                          'SCHEDULING',
                          'GAUGE_CHART',
                          'NOTIFICATIONS',
                        ].includes(selectedComponentType)
                      "
                      v-model="customDashboardData.includeGlobalDateFilter"
                      class="mb-1"
                      >Include global date filter</el-checkbox
                    >
                    <el-checkbox
                      v-if="selectedComponentType == 'TABLE'"
                      v-model="ShowExport"
                      class="mb-1"
                      >Show Export In Table
                    </el-checkbox>
                    <div v-if="selectedComponentType == 'SCHEDULING'">
                      <h4>Name</h4>
                      <el-input
                        v-model="schedulerComponentName"
                        placeholder="Component name"
                      ></el-input>
                      <h4 class="mt-1">Scheduling entity</h4>
                      <el-select
                        v-model="schedulerEntity"
                        no-data-text="No Entities available"
                        filterable
                        default-first-option
                        @change="(value) => getEntityFields(value, 'SCHEDULER')"
                      >
                        <el-option
                          v-for="(entity, index) of getAllEntitiesData"
                          :value="entity._id"
                          :key="index"
                          :label="entity.name"
                        >
                          <span style="float: left">{{ entity.name }}</span>
                        </el-option>
                      </el-select>
                      <template v-if="schedulerEntity">
                        <el-checkbox
                          v-if="
                            isEntityDashboard &&
                            getRelationshipEntities.find(
                              (e) => e._id == schedulerEntity
                            )
                          "
                          v-model="applyRelationship"
                          >Apply relationships</el-checkbox
                        >
                        <h4>Task date</h4>
                        <el-select
                          v-model="schedulerEntityDateField"
                          no-data-text="No date fields available"
                          filterable
                          default-first-option
                        >
                          <el-option
                            v-for="(
                              field, index
                            ) of schedulingEntityFields.filter((e) =>
                              ['DATE', 'DATE_TIME'].includes(e.inputType)
                            )"
                            :value="field.template_key"
                            :key="index + 'date_field'"
                            :label="field.label"
                          >
                          </el-option>
                        </el-select>
                        <h4>Resource entity</h4>
                        <el-select
                          v-model="resourceEntity"
                          no-data-text="No Entities available"
                          filterable
                          default-first-option
                          @change="
                            (value) =>
                              getEntityFields(
                                schedulingEntityFields.find(
                                  (e) => e.template_key == value
                                ).entity_id,
                                'RESOURCE'
                              )
                          "
                        >
                          <el-option
                            v-for="(
                              field, index
                            ) of schedulingEntityFields.filter((e) =>
                              ['ENTITY'].includes(e.inputType)
                            )"
                            :value="field.template_key"
                            :key="index + 'entity_field'"
                            :label="field.label"
                          >
                          </el-option>
                        </el-select>
                        <template v-if="resourceEntity">
                          <h4 class="mt-1">Job entity</h4>
                          <el-select
                            v-model="jobEntity"
                            no-data-text="No Entities available"
                            filterable
                            default-first-option
                            @change="
                              (value) =>
                                getEntityFields(
                                  schedulingEntityFields.find(
                                    (e) => e.template_key == value
                                  ).entity_id,
                                  'JOB'
                                )
                            "
                          >
                            <el-option
                              v-for="(
                                field, index
                              ) of schedulingEntityFields.filter(
                                (e) =>
                                  ['ENTITY'].includes(e.inputType) &&
                                  e.template_key !== resourceEntity
                              )"
                              :value="field.template_key"
                              :key="index + 'entity_field'"
                              :label="field.label"
                            >
                            </el-option>
                          </el-select>
                        </template>
                        <template v-if="resourceEntity">
                          <h4>Resource time</h4>
                          <el-select
                            v-model="resourceEntityTimeField"
                            no-data-text="No hours fields available"
                            filterable
                            default-first-option
                          >
                            <el-option
                              v-for="(
                                field, index
                              ) of resourceEntityFields.filter((e) =>
                                ['FORMULA', 'NUMBER'].includes(e.inputType)
                              )"
                              :value="field.template_key"
                              :key="index + 'date_field'"
                              :label="field.label"
                            >
                            </el-option>
                          </el-select>
                        </template>
                        <template v-if="jobEntity">
                          <h4>Job time</h4>
                          <el-select
                            v-model="jobEntityTimeField"
                            no-data-text="No hours fields available"
                            filterable
                            default-first-option
                          >
                            <el-option
                              v-for="(field, index) of jobEntityFields.filter(
                                (e) =>
                                  ['FORMULA', 'NUMBER'].includes(e.inputType)
                              )"
                              :value="field.template_key"
                              :key="index + 'date_field'"
                              :label="field.label"
                            >
                            </el-option>
                          </el-select>
                        </template>
                        <template v-if="schedulerEntity && resourceEntity">
                          <h4>Scheduling sub-group</h4>
                          <el-select
                            v-model="schedulerEntitySubGroups"
                            no-data-text="No entity fields available"
                            filterable
                            default-first-option
                            collapse-tags
                            multiple
                            @change="fetchSubGroupFilters"
                          >
                            <el-option
                              v-for="(
                                field, index
                              ) of schedulingEntityFields.filter(
                                (e) =>
                                  ['ENTITY'].includes(e.inputType) &&
                                  e.template_key !== resourceEntity &&
                                  e.template_key !== jobEntity
                              )"
                              :value="field.template_key"
                              :key="index + 'date_field'"
                              :label="field.label"
                            >
                            </el-option>
                          </el-select>
                          <template v-if="schedulerEntitySubGroups.length">
                            <h4>Divide sub-groups by</h4>
                            <el-select
                              v-model="schedulerEntitySubGroupsFilters"
                              filterable
                              default-first-option
                              collapse-tags
                              multiple
                            >
                              <el-option-group
                                v-for="entity of schedulerEntitySubGroupsData"
                                :key="entity.entity_id + '_sgf'"
                                :label="entity.label"
                              >
                                <el-option
                                  v-for="filter of entity.filters"
                                  :key="'_sgf_' + filter._id"
                                  :value="entity.key + '@' + filter._id"
                                  :label="filter.filter_name"
                                >
                                </el-option>
                              </el-option-group>
                            </el-select>
                          </template>
                        </template>
                      </template>
                    </div>
                    <div
                      v-else-if="
                        selectedComponentType == 'GAUGE_CHART' ||
                        selectedComponentType == 'NOTIFICATIONS'
                      "
                    >
                      <el-button
                        type="primary"
                        style="width: 100%"
                        @click="configModal = true"
                        >Configure <i class="el-icon-setting"></i
                      ></el-button>
                    </div>
                    <div v-else-if="selectedComponentType == 'CALENDAR'">
                      <h4>Entities</h4>
                      <el-select
                        v-model="selectedCalendarEntities"
                        no-data-text="No Entities available"
                        filterable
                        default-first-option
                        @change="getEntityDateFields"
                        multiple
                        collapse-tags
                      >
                        <el-option
                          v-for="(entity, index) of getAllEntitiesData"
                          :value="entity._id"
                          :key="index"
                          :label="entity.name"
                        >
                          <span style="float: left">{{ entity.name }}</span>
                        </el-option>
                      </el-select>
                    </div>
                    <div v-else-if="selectedComponentType == 'LOGINS'">
                      <h4>Entities</h4>
                      <el-select
                        v-model="selectedLoginEntity"
                        filterable
                        no-data-text="No Entities available"
                        @change="getLoginEntityDateFields"
                        clearable
                        :disabled="isEntitySelectDisabledForLogin"
                      >
                        <el-option
                          v-for="(entity, index) of getAllEntitiesData"
                          :value="entity._id"
                          :key="index"
                          :label="entity.name"
                        >
                          <span style="float: left">{{ entity.name }}</span>
                        </el-option>
                      </el-select>
                      <div
                        v-if="
                          isEntityDashboard &&
                          !selectedEntityData &&
                          selectedComponentType !== 'SCHEDULING'
                        "
                      >
                        <h4>Relational entities</h4>
                        <el-select
                          placeholder="Relational Entity"
                          v-model="selectedLoginEntityRelationalData"
                          @change="getLoginEntityDateFields"
                          clearable
                          :disabled="isRelationalSelectDisabled"
                        >
                          <el-option
                            v-for="(op, i) in getRelationshipEntities"
                            :key="op._id + i"
                            :value="op._id"
                            :label="op.name"
                            >{{ "My " + op.name }}</el-option
                          >
                        </el-select>
                      </div>
                    </div>
                    <div v-else-if="selectedComponentType == 'ENTITY_VIEW'">
                      <h4>Entity Views</h4>
                      <el-select
                        v-model="selectedEntityView"
                        clearable
                        filterable
                      >
                        <el-option
                          v-for="(view, index) of getAllEntityViews.data"
                          :key="index"
                          :value="view._id"
                          :label="view.name"
                        >
                        </el-option>
                      </el-select>
                      <el-checkbox
                        v-if="selectedEntityView"
                        v-model="isRelationalData"
                        >Apply relationship?</el-checkbox
                      >
                    </div>
                    <div
                      v-else-if="
                        selectedComponentType == 'MULTIPLE_CUSTOM_FILTER'
                      "
                    >
                      <h4>Filter by field</h4>
                      <el-select
                        v-model="multipleCustomFilterField"
                        filterable
                        default-first-option
                        class="mb-1"
                        @change="getComponentInfo"
                      >
                        <el-option
                          v-for="(field, index) of getCurrentEntitiesFields"
                          :value="`${field.entity_id}-${field.key}`"
                          :key="field.key + index"
                          :label="`${field.template_name} - ${field.label} `"
                        >
                          <span
                            >{{ field.template_name }} - {{ field.label }}</span
                          >
                        </el-option>
                      </el-select>
                      <h4>Entities</h4>
                      <el-select
                        v-model="selectedMulCustomFilterEntity"
                        filterable
                        no-data-text="No Entities available"
                        clearable
                        multiple
                        collapse-tags
                        :disabled="isEntitySelectDisabled"
                      >
                        <el-option
                          v-for="(entity, index) of entityOptions"
                          :value="entity._id"
                          :key="index"
                          :label="entity.name"
                        >
                          <span style="float: left">{{ entity.name }}</span>
                        </el-option>
                      </el-select>
                      <h4>Dependent on</h4>
                      <el-select
                        v-model="multipleFilterDependency"
                        filterable
                        no-data-text="No Entities available"
                        clearable
                      >
                        <el-option
                          v-for="(entity, index) of multipleFilterDependencies"
                          :value="entity.key"
                          :key="index"
                          :label="entity.label"
                        >
                        </el-option>
                      </el-select>
                    </div>
                    <div v-else-if="!selectedEntityRelationalData">
                      <h4>Entities</h4>
                      <el-select
                        v-model="selectedEntityData"
                        no-data-text="No Entities available"
                        filterable
                        default-first-option
                        @change="getComponentInfo"
                        clearable
                      >
                        <el-option
                          v-for="(entity, index) of selectedComponentType ==
                          'CUSTOM_FILTER'
                            ? getCurrentEntitiesData
                            : getAllEntitiesData"
                          :value="entity._id"
                          :key="index"
                          :label="entity.name"
                        >
                          <span style="float: left">{{ entity.name }}</span>
                        </el-option>
                      </el-select>
                    </div>
                    <div
                      v-if="
                        isEntityDashboard &&
                        !selectedEntityData &&
                        ![
                          'CALENDAR',
                          'LOGINS',
                          'ENTITY_VIEW',
                          'GAUGE_CHART',
                          'NOTIFICATIONS',
                          'SCHEDULING',
                        ].includes(selectedComponentType)
                      "
                    >
                      <h4>Relational entities</h4>
                      <el-select
                        placeholder="Relational Entity"
                        v-model="selectedEntityRelationalData"
                        @change="getComponentInfo"
                        clearable
                      >
                        <el-option
                          v-for="(op, i) in getRelationshipEntities"
                          :key="op._id + i"
                          :value="op._id"
                          :label="op.name"
                          >{{ "My " + op.name }}</el-option
                        >
                      </el-select>
                    </div>
                    <div
                      v-if="
                        isEntityDashboard &&
                        !selectedEntityData &&
                        selectedComponentType == 'CALENDAR'
                      "
                    >
                      <h4>Relational entities</h4>
                      <el-checkbox
                        v-if="
                          selectedCalendarRelationEntities &&
                          selectedCalendarRelationEntities.length
                        "
                        v-model="allowOtherShowData"
                      >
                        Allow others in filter
                      </el-checkbox>
                      <el-select
                        placeholder="Relational Entity"
                        v-model="selectedCalendarRelationEntities"
                        clearable
                        @change="getEntityDateFields"
                        multiple
                      >
                        <el-option
                          v-for="(op, i) in getRelationshipEntities"
                          :key="op._id + i"
                          :value="op._id"
                          :label="op.name"
                          >{{ "My " + op.name }}</el-option
                        >
                      </el-select>
                      <h4>Nested Relational Entities</h4>
                      <el-select
                        placeholder="Nested Relational Entity"
                        v-model="selectedCalendarNestedRelationEntities"
                        clearable
                        @change="getEntityDateFields"
                        multiple
                      >
                        <el-option
                          v-for="(op, i) in getNestedRelationshipEntities"
                          :key="op.key + i"
                          :value="op.key"
                          :label="op.name"
                        ></el-option>
                      </el-select>
                    </div>
                    <div
                      class="mt-1"
                      v-if="
                        selectedComponentType == 'TABLE' ||
                        selectedComponentType == 'CARD'
                      "
                    >
                      <el-select
                        placeholder="Group by"
                        v-model="tableFieldGroupBy"
                        v-if="
                          selectedComponentType == 'TABLE' && selectedEntityData
                        "
                        clearable
                      >
                        <el-option
                          v-for="(field, index) of getTemplateFields"
                          :value="field.key"
                          :key="field.key + index"
                          :label="`${field.template_name} - ${field.label} `"
                        >
                          <span
                            >{{ field.template_name }} - {{ field.label }}</span
                          >
                        </el-option>
                      </el-select>
                      <el-select
                        placeholder="Sub group by"
                        v-model="tableFieldSubGroupBy"
                        v-if="
                          selectedComponentType == 'TABLE' && tableFieldGroupBy
                        "
                        clearable
                        @change="addSubGroupCount"
                      >
                        <el-option
                          v-for="(field, index) of getTemplateFields.filter(
                            (e) =>
                              e.input_type != 'MULTI_SELECT' &&
                              e.key != tableFieldGroupBy
                          )"
                          :value="field.key"
                          :key="field.key + index"
                          :label="`${field.template_name} - ${field.label} `"
                        >
                          <span
                            >{{ field.template_name }} - {{ field.label }}</span
                          >
                        </el-option>
                      </el-select>
                      <el-select
                        placeholder="Select options to group"
                        multiple
                        v-model="tableFieldGroupBySelectedOptions"
                        v-if="tableFieldSubGroupBy"
                        :clearable="true"
                        class="mt-1"
                        collapse-tags
                      >
                        <template
                          v-if="checkFieldType(tableFieldSubGroupBy, 'ENTITY')"
                        >
                          <el-option
                            v-for="(data, index) of groupByFieldEntityValues"
                            :key="index + 'entity' + data._id"
                            :value="data._id"
                            :label="getLabel(data, index, groupByFieldEntity)"
                          >
                            <span>{{
                              getLabel(data, index, groupByFieldEntity)
                            }}</span>
                          </el-option>
                        </template>
                        <template v-else>
                          <el-option
                            v-for="(
                              option, index
                            ) of pieChartFieldGroupByOptions"
                            :value="option"
                            :key="index"
                            :label="option"
                          >
                            <span>{{ option }}</span>
                          </el-option>
                        </template>
                      </el-select>
                      <div v-if="tableFieldGroupBy" class="mt-1">
                        <el-tag
                          v-for="(field, index) in tableFieldGroupByFields"
                          :key="index + 'name'"
                          :closable="
                            !tableFieldGroupByFields ||
                            !tableFieldGroupByFields.length ||
                            (tableFieldGroupByFields &&
                              tableFieldGroupByFields.length == 1)
                              ? false
                              : true
                          "
                          :type="
                            field && field.sub_group ? 'success' : 'primary'
                          "
                          style="margin-right: 5px; margin-top: 5px"
                          >{{ field.name }}</el-tag
                        >
                        <el-link
                          icon="el-icon-circle-plus-outline"
                          :underline="false"
                          type="primary"
                          style="margin-left: 3px"
                          @click="openTableColums"
                        ></el-link>
                      </div>
                      <h4 class="mt-2">Filters</h4>
                      <el-input
                        v-model="filterSearchString"
                        placeholder="Search"
                        prefix-icon="el-icon-search"
                        @input="searchFilters()"
                      ></el-input>
                      <el-scrollbar wrap-style="max-height: 300px;">
                        <el-checkbox-group
                          v-model="selectedFilter"
                          class="float-left checkboxOrder"
                          v-if="
                            selectedEntityData || selectedEntityRelationalData
                          "
                        >
                          <el-checkbox
                            v-for="(filter, index) of filters.filter(
                              (e) => !e.parent_filter_id
                            )"
                            :key="index"
                            class="mt-1"
                            :label="filter._id"
                            >{{ filter.filter_name }}</el-checkbox
                          >
                        </el-checkbox-group>
                      </el-scrollbar>
                    </div>
                    <div v-else-if="selectedComponentType == 'CUSTOM_FILTER'">
                      <h4>Filter by field</h4>
                      <el-select
                        v-model="customFilterField"
                        filterable
                        default-first-option
                        class="mb-1"
                        multiple
                        collapse-tags
                      >
                        <el-option
                          v-for="(field, index) of getTemplateFields"
                          :value="field.key"
                          :key="field.key + index"
                          :label="`${field.template_name} - ${field.label} `"
                        >
                          <span
                            >{{ field.template_name }} - {{ field.label }}</span
                          >
                        </el-option>
                      </el-select>
                    </div>
                    <br />
                    <div v-if="selectedComponentType == 'CALENDAR'">
                      <div style="display: inline-flex">
                        <h4>Select field:</h4>
                        <el-link
                          icon="el-icon-circle-plus"
                          :disabled="
                            selectedCalendarEntities.length == 0 &&
                            selectedCalendarRelationEntities.length == 0 &&
                            selectedCalendarNestedRelationEntities.length == 0
                          "
                          @click="displayPopup"
                          style="
                            margin-top: 3px;
                            margin-left: 60px;
                            color: #f754a2;
                            height: 16px;
                            width: 16px;
                          "
                        ></el-link>
                      </div>
                      <div>
                        <div
                          v-for="dt in this.calenderDateField"
                          :key="dt.field"
                        >
                          <el-tag
                            v-if="
                              dt &&
                              dt.field &&
                              dt.field.includes('#') &&
                              dt.field.split('#')[2]
                            "
                            >{{ dt.field.split("#")[2] }}</el-tag
                          >
                        </div>
                      </div>
                    </div>
                    <div v-if="selectedComponentType == 'LOGINS'">
                      <div style="display: inline-flex">
                        <h4>Map field:</h4>
                        <el-link
                          icon="el-icon-circle-plus"
                          @click="displayLoginsPopup"
                          style="
                            margin-top: 3px;
                            margin-left: 60px;
                            color: #f754a2;
                            height: 16px;
                            width: 16px;
                          "
                        ></el-link>
                      </div>
                      <div>
                        <div v-if="this.loginsDataDetails.length > 0">
                          <el-tag>Data</el-tag>
                        </div>
                      </div>
                    </div>
                    <div v-if="selectedComponentType == 'CHART'">
                      <div style="display: inline-flex">
                        <h4>Map Data:</h4>
                        <el-link
                          icon="el-icon-circle-plus"
                          @click="displayChartsPopup"
                          style="
                            margin-top: 3px;
                            margin-left: 60px;
                            color: #f754a2;
                            height: 16px;
                            width: 16px;
                          "
                        ></el-link>
                      </div>
                      <div>
                        <div v-if="this.chartType">
                          <el-tag>Data</el-tag>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-scrollbar>
                <el-button
                  class="mt-1"
                  type="primary"
                  style="width: 100%"
                  @click="insertComponent"
                  :disabled="checkIsDisabled"
                  >Insert</el-button
                >
              </div>
            </el-col>
          </el-row>
        </div>
      </el-col>
      <el-col :span="getIsOpen ? 20 : 24" class="configuration-cards">
        <div>
          <el-row
            v-if="
              (customDashboardData &&
                customDashboardData.allCustomFilters &&
                customDashboardData.allCustomFilters.length) ||
              customDashboardData.includeGlobalDateFilter ||
              customDashboardData.allMulCustomFilters.length ||
              customDashboardData.allIntegrationFilters.length
            "
            style="padding-left: 25px"
            :gutter="30"
          >
            <el-col :span="24">
              <CustomDashboardFilters
                v-if="dateFilter"
                :allDashboardstats="customDashboardData"
                :getAllEntities="getAllEntities"
                :getAllCompanyTemplatesData="{ data: allTemplates }"
                :permittedEntities="permittedEntities"
                :permittedRelatedEntities="permittedRelatedEntities"
                :permittedNestedRelatedEntities="permittedNestedRelatedEntities"
                :integrations="integrations"
                :predefinedIntegrations="predefinedIntegrations"
                @update:selectedDatePicker="updateDateFilter"
                @update:CustomDate="updateCustomDateFilter"
              ></CustomDashboardFilters>
            </el-col>
          </el-row>
          <el-row :gutter="30" style="padding-left: 25px">
            <el-col :span="24">
              <CustomDashboardStats
                :allDashboardstats="customDashboardData"
                :getRelationshipEntities="getRelationshipEntities"
                :getNestedRelationshipEntities="getNestedRelationshipEntities"
                :isFromApplicationuser="isEntityDashboard"
                :globalVariablesData="globalVariablesData"
                :getAllEntities="getAllEntities"
                :getAllCompanyTemplatesData="{ data: allTemplates }"
                :permittedEntities="permittedEntities"
                :permittedRelatedEntities="permittedRelatedEntities"
                :permittedNestedRelatedEntities="permittedNestedRelatedEntities"
                :integrations="integrations"
                :predefinedIntegrations="predefinedIntegrations"
                @addIntegrationsFilters="checkAndAddGlobalFilters"
                @DateFilterUpdate="DateFilterUpdate"
                @update-dashboardstats="updateDashboardStats"
              ></CustomDashboardStats>
            </el-col>
          </el-row>
          <div class="wrapper-grid" v-if="!refreshComponents">
            <draggable class="drag-grid" @end="switchPositions">
              <div
                v-for="(
                  component, index
                ) in customDashboardData.allTableComponents"
                :style="
                  expendedIndexs.indexOf(index) !== -1 ||
                  getIsMobile ||
                  component.type == 'SCHEDULING'
                    ? 'grid-column: 1/3;'
                    : ''
                "
                :key="
                  component.entity_id +
                  '_' +
                  (component.filter_id
                    ? component.filter_id
                    : component.pie_chart_field) +
                  index +
                  '_component'
                "
                class="grid-item"
              >
                <CustomDashboardTable
                  v-if="component.type == 'TABLE'"
                  :getRelationshipEntities="getRelationshipEntities"
                  :component="component"
                  :key="
                    component.entity_id +
                    '_' +
                    component.filter_id +
                    index +
                    '_table'
                  "
                  :entity_id="component.entity_id"
                  :filter_id="component.filter_id"
                  :index="index"
                  :globalVariablesData="globalVariablesData"
                  :customDashboardData="customDashboardData"
                  :rawData="rawData"
                  :getAllEntities="getAllEntities"
                  :getAllCompanyTemplatesData="{ data: allTemplates }"
                  :permittedEntities="permittedEntities"
                  :permittedRelatedEntities="permittedRelatedEntities"
                  :permittedNestedRelatedEntities="
                    permittedNestedRelatedEntities
                  "
                  @expendTable="expendTable"
                  @deleteComponent="deleteComponent"
                  @tableUpdate="tableUpdate"
                ></CustomDashboardTable>
                <CustomDashboardPieChart
                  v-else-if="component.type == 'CHART'"
                  :component="component"
                  :hide_options="false"
                  :hide_expend="false"
                  :index="index"
                  :customDashboardData="customDashboardData"
                  :globalVariablesData="globalVariablesData"
                  :getAllEntities="getAllEntities"
                  :getAllCompanyTemplatesData="{ data: allTemplates }"
                  :permittedEntities="permittedEntities"
                  :permittedRelatedEntities="permittedRelatedEntities"
                  :permittedNestedRelatedEntities="
                    permittedNestedRelatedEntities
                  "
                  @expendTable="expendTable"
                  @deleteComponent="deleteComponent"
                  @tableUpdate="tableUpdate"
                  :getRelationshipEntities="getRelationshipEntities"
                ></CustomDashboardPieChart>
                <CustomDashboardCalendar
                  v-if="component.type == 'CALENDAR'"
                  :component="component"
                  :getNestedRelationshipEntities="getNestedRelationshipEntities"
                  :index="index"
                  :customDashboard="customDashboardData"
                  :globalVariablesData="globalVariablesData"
                  :getAllEntities="getAllEntities"
                  :getAllCompanyTemplatesData="{ data: allTemplates }"
                  :permittedEntities="permittedEntities"
                  :permittedRelatedEntities="permittedRelatedEntities"
                  :permittedNestedRelatedEntities="
                    permittedNestedRelatedEntities
                  "
                  @expendTable="expendTable"
                  @deleteComponent="deleteComponent"
                  @tableUpdate="tableUpdate"
                ></CustomDashboardCalendar>
                <CustomDashboardCard
                  v-else-if="component.type == 'CARD'"
                  :component="component"
                  :key="
                    component.entity_id +
                    '_' +
                    component.filter_id +
                    index +
                    '_card'
                  "
                  :entity_id="component.entity_id"
                  :filter_id="component.filter_id"
                  :index="index"
                  :globalVariablesData="globalVariablesData"
                  :customDashboardData="customDashboardData"
                  :getAllEntities="getAllEntities"
                  :getAllCompanyTemplatesData="{ data: allTemplates }"
                  :permittedEntities="permittedEntities"
                  :permittedRelatedEntities="permittedRelatedEntities"
                  :permittedNestedRelatedEntities="
                    permittedNestedRelatedEntities
                  "
                ></CustomDashboardCard>
                <CustomDashboardLogins
                  v-else-if="component.type == 'LOGINS'"
                  :component="component"
                  :index="index"
                  :customDashboard="customDashboardData"
                  :getAllEntities="getAllEntities"
                  :getAllCompanyTemplatesData="{ data: allTemplates }"
                  :permittedEntities="permittedEntities"
                  :permittedRelatedEntities="permittedRelatedEntities"
                  :permittedNestedRelatedEntities="
                    permittedNestedRelatedEntities
                  "
                  @expendTable="expendTable"
                  @deleteComponent="deleteComponent"
                  @tableUpdate="tableUpdate"
                ></CustomDashboardLogins>
                <EntityViews
                  v-else-if="component.type == 'ENTITY_VIEW'"
                  :entityViewId="component.entity_view_id"
                  :fromCustomDashboard="true"
                  :index="index"
                  :component="component"
                  :customDashboardData="customDashboardData"
                  :getAllEntities="getAllEntities"
                  :getAllCompanyTemplatesData="{ data: allTemplates }"
                  :permittedEntities="permittedEntities"
                  :permittedRelatedEntities="permittedRelatedEntities"
                  :permittedNestedRelatedEntities="
                    permittedNestedRelatedEntities
                  "
                  @deleteComponent="deleteComponent"
                  @expendTable="expendTable"
                  @viewUpdate="tableUpdate"
                  :hide_options="false"
                >
                </EntityViews>
                <SchedulingEntity
                  v-else-if="component.type == 'SCHEDULING'"
                  :getAllEntities="getAllEntities"
                  :getAllCompanyTemplatesData="allTemplates"
                  :permittedEntities="permittedEntities"
                  :permittedRelatedEntities="permittedRelatedEntities"
                  :permittedNestedRelatedEntities="
                    permittedNestedRelatedEntities
                  "
                  :component="component"
                  :index="index"
                  :hide_options="false"
                  :isFromApplicationuser="isEntityDashboard"
                  @deleteComponent="deleteComponent"
                ></SchedulingEntity>
                <CustomDashboardGauge
                  v-else-if="component.type == 'GAUGE_CHART'"
                  :component="component"
                  :index="index"
                  :getAllCompanyTemplatesData="{ data: allTemplates }"
                  :hide_options="false"
                  @editComponent="editGauge"
                  @deleteComponent="deleteComponent"
                  @expendTable="expendTable"
                >
                </CustomDashboardGauge>
                <CustomDashboardNotifications
                  v-else-if="component.type == 'NOTIFICATIONS'"
                  :component="component"
                  :index="index"
                  :hide_options="false"
                  @deleteComponent="deleteComponent"
                  @editComponent="editNotifications"
                >
                </CustomDashboardNotifications>
                <!-- <CustomDashboardGanttChart
                v-else-if="component.type == 'GANTT_CHART'"
                :component="component"
                  :index="index"
                  :hide_options="false"
                  @deleteComponent="deleteComponent"
                  @editComponent="editNotifications">
                </CustomDashboardGanttChart> -->
              </div>
            </draggable>
          </div>
        </div>
        <dialog-component
          :visible="goBackToCustom"
          :title="'Warning'"
          :containerWidth="'35%'"
          @before-close="backToCustom"
        >
          <div class="popUp-content m-tb-5">
            <span>
              <h5>It looks like you have been modifying something</h5>
            </span>
            <span
              >If you leave before updating, your changes will be lost.</span
            >
          </div>
          <span slot="footer">
            <el-button type="plain" @click="goBackToCustom = false"
              >Cancel</el-button
            >
            <el-button type="primary" @click="backToCustom">Confirm</el-button>
          </span>
        </dialog-component>

        <dialog-component
          :title="'Calendar Component'"
          :visible="centerDialogVisible"
          @before-close="CenterDialogVisibleClose"
          :containerWidth="'72%'"
          :containerMinHeight="'20%'"
          draggable
        >
          <div v-if="tableData" class="mt-10 mr-10">
            <label>Component name</label>
            <span class="text-danger">*</span>
            <el-input
              v-model="calendarName"
              placeholder="Enter component name"
              class="mb-1"
            ></el-input>
          </div>
          <el-scrollbar class="scrollbar-container">
            <div class="scrollable-table-container mr-10">
              <el-table :data="tableData" border>
                <el-table-column prop="Contact Type" label="Fields" width="180">
                  <template slot-scope="scope">
                    <el-select
                      v-model="scope.row.field"
                      filterable
                      default-first-option
                      class="mb-1 mt-1"
                      @change="getEntityFilters"
                      clearable
                    >
                      <el-option
                        v-for="(field, index) of getTemplateDateFields"
                        :value="field.key"
                        :key="field.key + index"
                        :label="`${field.entity_name} - ${field.template_name} - ${field.label} `"
                        :disabled="
                          (tableData || []).findIndex(
                            (ex) => ex.field == field.key
                          ) != -1
                        "
                      >
                        <span>
                          {{ field.entity_name }} - {{ field.template_name }} -
                          {{ field.label }}
                        </span>
                      </el-option>
                    </el-select>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="Custom Message"
                  label="Custom Message *"
                  width="250"
                >
                  <template slot-scope="scope">
                    <div
                      v-if="
                        scope.row.field &&
                        scope.row.field.includes('#') &&
                        scope.row.field.split('#')[0]
                      "
                    >
                      <CustomEntityMention
                        :entity_id="scope.row.field.split('#')[0]"
                        :data="scope.row"
                      ></CustomEntityMention>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column prop="Filter" label="Filter">
                  <template slot-scope="scope">
                    <el-select
                      clearable
                      v-model="scope.row.filter"
                      placeholder="Select filter"
                      v-if="
                        scope.row.field &&
                        scope.row.field.includes('#') &&
                        scope.row.field.split('#')[0]
                      "
                    >
                      <el-option
                        v-for="(op, i) of (
                          getCurrenEntityFilter(
                            scope.row.field.split('#')[0]
                          ) || []
                        ).filter((e) => !e.parent_filter_id)"
                        :key="i + op._id"
                        :value="op._id"
                        :label="op.filter_name"
                      ></el-option>
                    </el-select>
                  </template>
                </el-table-column>

                <el-table-column prop="show every year" label="Show every year">
                  <template slot-scope="scope">
                    <div class="d-flex">
                      <div>
                        <el-checkbox
                          class="mr-2"
                          type="textarea"
                          v-model="scope.row.show_every_year"
                        ></el-checkbox>

                        <el-link
                          class="mr-2"
                          @click.native.prevent="
                            deleteRow(scope.$index, tableData)
                          "
                          type="danger"
                          icon="el-icon-delete"
                        ></el-link>
                      </div>
                      <div>
                        <el-color-picker
                          size="mini"
                          v-model="scope.row.color"
                        ></el-color-picker>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="Show background color">
                  <template slot-scope="scope">
                    <div>
                      <el-checkbox
                        type="textarea"
                        v-model="scope.row.applyBackground"
                      ></el-checkbox>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-scrollbar>
          <br />
          <br />
          <div class="calendar-header mb-05">
            <div class="child-a">
              <p><b>HINT:</b>Type @ to insert entity field</p>
            </div>
            <div class="child-a mr-2">
              <el-link
                icon="el-icon-circle-plus"
                type="primary"
                @click="addNewRow"
                style="float: right"
                >Add</el-link
              >
            </div>
          </div>

          <span slot="footer" class="dialog-footer">
            <el-button @click="resetEventModal" class="mr-1">Cancel</el-button>
            <el-button
              type="primary"
              @click="insertCalendarFields"
              :disabled="disableSave"
              >Save</el-button
            >
          </span>
        </dialog-component>
        <dialog-component
          :title="'Add table columns'"
          :visible="openTableColumsModal"
          :containerWidth="'50%'"
          @before-close="openTableColumsModalClose"
          draggable
        >
          <el-row>
            <el-link
              icon="el-icon-circle-plus"
              type="primary"
              @click="addNewColumn"
              style="float: right"
              :underline="false"
              >Add</el-link
            >
          </el-row>
          <el-row>
            <el-scrollbar
              wrap-style="max-height: 250px; width: 100% !important;"
            >
              <el-table :data="tableFieldGroupByFieldsModal" border>
                <el-table-column
                  prop="Group"
                  label="Is sub group?"
                  width="180"
                  v-if="tableFieldSubGroupBy"
                >
                  <template slot-scope="scope">
                    <el-checkbox
                      v-model="scope.row.sub_group"
                      :disabled="scope.row.key ? true : false"
                      >Is sub group?</el-checkbox
                    >
                  </template>
                </el-table-column>
                <el-table-column prop="Column" label="Column" width="180">
                  <template slot-scope="scope">
                    <el-select
                      v-model="scope.row.key"
                      placeholder="Table columns"
                      v-if="tableFieldGroupBy"
                      clearable
                      @change="fillLabel(scope.row.key, scope.$index)"
                    >
                      <el-option
                        label="Count"
                        value="count"
                        :disabled="
                          tableFieldGroupByFieldsModal.find(
                            (e) =>
                              e.key == 'count' &&
                              scope.row.sub_group == e.sub_group
                          )
                            ? true
                            : false
                        "
                      ></el-option>
                      <el-option-group label="Sum">
                        <el-option
                          v-for="(field, index) of getTotalFields"
                          :value="field.key"
                          :key="field.key + index"
                          :label="`${field.template_name} - ${field.label} `"
                          :disabled="
                            tableFieldGroupByFieldsModal.find(
                              (e) =>
                                e.key == field.key &&
                                scope.row.sub_group == e.sub_group
                            )
                              ? true
                              : false
                          "
                        >
                          <span
                            >{{ field.template_name }} - {{ field.label }}</span
                          >
                        </el-option>
                      </el-option-group>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="Contact Type"
                  label="Column name"
                  width="180"
                >
                  <template slot-scope="scope">
                    <el-input
                      placeholder="Enter name"
                      v-model="scope.row.name"
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="Actions" label="Actions" width="180">
                  <template slot-scope="scope">
                    <el-link
                      @click.native.prevent="
                        deleteRow(scope.$index, tableFieldGroupByFieldsModal)
                      "
                      type="danger"
                      icon="el-icon-delete"
                      :underline="false"
                    ></el-link>
                  </template>
                </el-table-column>
              </el-table>
            </el-scrollbar>
          </el-row>
          <span slot="footer" class="dialog-footer">
            <el-button @click="openTableColumsModal = false">Cancel</el-button>
            <el-button
              type="primary"
              @click="saveTableGroupBy"
              :disabled="checkTableGroupByField"
              >Save</el-button
            >
          </span>
        </dialog-component>
        <dialog-component
          :title="'Logins Component'"
          :visible="centerLoginsDialogVisible"
          @before-close="loginPopUpClose"
          width="70%"
          draggable
        >
          <label>start time</label>
          <el-input
            v-model="start_button"
            placeholder="Enter component name"
            class="mb-1"
          ></el-input>
          <label>end time</label>
          <el-input
            v-model="end_button"
            placeholder="Enter component name"
            class="mb-1"
          ></el-input>
          <el-table :data="info" border>
            <el-table-column label="componentinfo">
              <template slot-scope="scope">
                {{ scope.row.component }}
              </template>
            </el-table-column>
            <el-table-column prop="Contact Type" label="Fields" width="250">
              <template slot-scope="scope">
                <el-select v-model="scope.row.field" size="mini" clearable>
                  <el-option
                    v-for="(field, i) of allDateTimeFields"
                    :key="field + i"
                    :value="field.template_key"
                    :label="field.label"
                    :disabled="isFieldDisabled(field)"
                  >
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
          </el-table>
          <el-row>
            <!-- <el-col   :span="12" v-if="displayAutoLogout">
              <div style="display: flex; flex-direction: column; align-items: flex-start;">
                <div style="display: flex; align-items: center;">
                <label>Auto logout </label>
        </div>
        <el-input
          v-model="auto_logout"
          type ="number"
          placeholder="Enter number "
          class="mb-1"
          style="width:95% ;"
        ></el-input>
              </div>
            </el-col> -->
            <el-col :span="12">
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                "
                v-if="isEntityDashboard && !selectedEntityData"
              >
                <div style="display: flex; align-items: center">
                  <label>Select User Field</label>
                </div>
                <el-select v-model="user_field" clearable style="width: 95%">
                  <el-option
                    v-for="(field, i) of entityField"
                    :key="field + i"
                    :value="field.template_key"
                    :label="field.label"
                  >
                  </el-option>
                </el-select>
              </div>
              <div v-if="!isEntityDashboard">
                <div style="display: flex; align-items: center">
                  <label>Select Field</label>
                  <el-popover
                    placement="top-start"
                    trigger="hover"
                    content="add singleline field to store user data "
                  >
                    <i class="el-icon-info" slot="reference"></i>
                  </el-popover>
                </div>
                <el-select
                  v-model="companyuser_field"
                  clearable
                  style="width: 95%"
                >
                  <el-option
                    v-for="(field, i) of textField"
                    :key="field + i"
                    :value="field.template_key"
                    :label="field.label"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <div v-if="shouldDisplayInput">
                <div style="display: flex; align-items: center">
                  <label>No. of days</label>
                </div>
                <el-input
                  v-model="Days"
                  type="number"
                  placeholder="Enter number of days"
                  class="mb-1"
                  style="width: 95%"
                ></el-input>
              </div>
            </el-col>
          </el-row>
          <span slot="footer" class="dialog-footer">
            <el-button @click="centerLoginsDialogVisible = false" class="mr-1"
              >Cancel</el-button
            >
            <el-button
              type="primary"
              @click="saveLoginsData"
              :disabled="disableloginSave"
              >Save</el-button
            >
          </span>
        </dialog-component>
        <dialog-component
          :title="'Charts Component'"
          :visible="centerChartsDialogVisible"
          @before-close="chartsPopUpClose"
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          :containerWidth="'50%'"
        >
          <div>
            <div class="mt-1 mr-1 mb-1">
              <h4>Enter Component Name:</h4>
              <el-input v-model="chartComponentName"></el-input>
            </div>
            <el-row>
              <el-col :span="12">
                <h4>Chart type</h4>
                <el-select
                  v-model="chartType"
                  filterable
                  default-first-option
                  class="mb-1"
                >
                  <el-option
                    v-for="(field, index) of chartTypes"
                    :value="field.value"
                    :key="field.value + index"
                    :label="field.label"
                  >
                    <span>{{ field.label }}</span>
                  </el-option>
                </el-select>
              </el-col>
              <el-col :span="12">
                <h4>Select field</h4>
                <el-select
                  v-model="pieChartField"
                  filterable
                  default-first-option
                  class="mb-1"
                  @change="fetchFieldOptionsForChart(pieChartField)"
                >
                  <el-option
                    v-for="(field, index) of getTemplateFields"
                    :value="field.key"
                    :key="field.key + index"
                    :label="`${field.template_name} - ${field.label} `"
                  >
                    <span>{{ field.template_name }} - {{ field.label }}</span>
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <h4 v-if="pieChartField">Select Options</h4>
                <el-select
                  placeholder="Select options for chart"
                  multiple
                  v-model="pieChartFieldOptions"
                  v-if="pieChartField"
                  :clearable="true"
                  collapse-tags
                  class="mb-1"
                >
                  <template v-if="checkFieldType(pieChartField, 'ENTITY')">
                    <el-option
                      v-for="(data, index) of pieChartFieldEntityValues"
                      :key="index + 'entity' + data._id"
                      :value="data._id"
                      :label="getLabel(data, index, pieChartFieldEntity)"
                    >
                      <span>{{
                        getLabel(data, index, pieChartFieldEntity)
                      }}</span>
                    </el-option>
                  </template>
                  <template v-else>
                    <el-option
                      v-for="(option, index) of pieChartFieldSelectOptions"
                      :value="option"
                      :key="index"
                      :label="option"
                    >
                      <span>{{ option }}</span>
                    </el-option>
                  </template>
                </el-select>
              </el-col>
              <el-col :span="12">
                <h4>Filter</h4>
                <!-- chartFilters -->
                <el-select
                  placeholder="Select filter"
                  clearable
                  v-model="chartSelectedFilter"
                  class="mb-1"
                >
                  <el-option
                    v-for="fl in chartFilters.filter(
                      (e) => !e.parent_filter_id
                    )"
                    :key="fl._id"
                    :value="fl._id"
                    :label="fl.filter_name"
                  ></el-option>
                </el-select>
              </el-col>
            </el-row>
            <el-row>
              <template v-if="chartType == 'COMBINATION'">
                <el-row>
                  <el-col :span="12">
                    <h4>Show total</h4>
                  </el-col>
                  <el-col :span="8">
                    <h4>Select Type</h4>
                  </el-col>
                </el-row>
                <el-row v-for="(row, index) in rows" :key="index">
                  <el-col :span="12">
                    <el-select
                      v-model="row.sum_field"
                      placeholder="Total field"
                      class="mb-1"
                      clearable
                    >
                      <el-option
                        v-for="(field, fieldIndex) in getTotalFields"
                        :value="field.key"
                        :key="field.key + fieldIndex"
                        :label="`${field.template_name} - ${field.label} `"
                        :disabled="
                          isOptionDisabled(row.sum_field, field.key, index)
                        "
                      >
                        <span
                          >{{ field.template_name }} - {{ field.label }}</span
                        >
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="8">
                    <el-select
                      v-model="row.chart_type"
                      filterable
                      default-first-option
                      class="mb-1"
                    >
                      <el-option
                        v-for="(field, fieldIndex) in subChartTypes"
                        :value="field.value"
                        :key="field.value + fieldIndex"
                        :label="field.label"
                      >
                        <span>{{ field.label }}</span>
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="1">
                    <el-link
                      :underline="false"
                      type="primary"
                      style="margin-left: 3px"
                      @click="addRow"
                    >
                      <i class="el-icon-circle-plus"></i>
                    </el-link>
                  </el-col>
                  <el-col :span="1">
                    <el-link
                      :underline="false"
                      type="primary"
                      style="margin-left: 3px"
                      @click="removeRow(index)"
                    >
                      <i class="el-icon-remove"></i>
                    </el-link>
                  </el-col>
                </el-row>
              </template>

              <template v-else>
                <el-row>
                  <el-col>
                    <h4>Show total</h4>
                    <div class="flex-container mb-1">
                      <el-select
                        v-model="sum_fields"
                        placeholder="Total field"
                        class="flex-item"
                        clearable
                        multiple
                        :multiple-limit="pieChartFieldGroupBy ? 1 : null"
                        collapse-tags
                        @change="
                          sum_fields && sum_fields.length > 1
                            ? (pieChartFieldGroupBy = '')
                            : ''
                        "
                      >
                        <el-option
                          v-for="(field, index) of getTotalFields"
                          :value="field.key"
                          :key="field.key + index"
                          :label="`${field.template_name} - ${field.label} `"
                        >
                          <span
                            >{{ field.template_name }} - {{ field.label }}</span
                          >
                        </el-option>
                      </el-select>
                      <el-color-picker
                        v-if="chartType == 'BAR' && sum_fields.length <= 1"
                        v-model="BarColor"
                        class="flex-item"
                        size="mini"
                      ></el-color-picker>
                    </div>
                  </el-col>
                  <el-col>
                    <el-checkbox
                      v-model="isStacked"
                      v-if="chartType == 'BAR' && sum_fields.length > 1"
                      class="mt-1 mr-1"
                      >Stacked</el-checkbox
                    >
                    <el-switch
                      size="mini"
                      v-if="isStacked"
                      v-model="startFrom"
                      :active-value="true"
                      :inactive-value="false"
                    ></el-switch>
                    <span v-if="isStacked">Start From 0</span>
                  </el-col>
                </el-row>
              </template>
            </el-row>
            <el-select
              placeholder="Group by"
              v-model="pieChartFieldGroupBy"
              v-if="
                pieChartField &&
                (!sum_fields || !sum_fields.length || sum_fields.length == 1) &&
                chartType != 'COMBINATION'
              "
              :clearable="true"
              @change="getSelectedFieldOptions"
            >
              <el-option
                v-for="(field, index) of getTemplateFields.filter(
                  (e) => e.key != pieChartField && e.input_type != 'DATE'
                )"
                :value="field.key"
                :key="field.key + index"
                :label="`${field.template_name} - ${field.label} `"
              >
                <span>{{ field.template_name }} - {{ field.label }}</span>
              </el-option>
            </el-select>
            <el-checkbox v-model="includeChartTotal" v-if="pieChartFieldGroupBy"
              >Include total</el-checkbox
            >
            <el-select
              placeholder="Select options to group"
              multiple
              v-model="pieChartFieldGroupBySelectedOptions"
              v-if="pieChartFieldGroupBy"
              :clearable="true"
              class="mt-1"
              collapse-tags
            >
              <template v-if="checkFieldType(pieChartFieldGroupBy, 'ENTITY')">
                <el-option
                  v-for="(data, index) of groupByFieldEntityValues"
                  :key="index + 'entity' + data._id"
                  :value="data._id"
                  :label="getLabel(data, index, groupByFieldEntity)"
                >
                  <span>{{ getLabel(data, index, groupByFieldEntity) }}</span>
                </el-option>
              </template>
              <template v-else>
                <el-option
                  v-for="(option, index) of pieChartFieldGroupByOptions"
                  :value="option"
                  :key="index"
                  :label="option"
                >
                  <span>{{ option }}</span>
                </el-option>
              </template>
            </el-select>
            <br />
            Apply date filter on this field:
            <el-select
              placeholder="Select date field"
              v-model="date_filter_field"
              default-first-option
              style="width: 100%"
              clearable
            >
              <el-option
                v-for="(field, index) of getAllDateFields"
                :value="field.key"
                :key="field.key + '_stat_' + index"
                :label="`${field.template_name} - ${field.label} `"
              >
                <span>{{ field.template_name }} - {{ field.label }}</span>
              </el-option>
              <el-option value="created_at" label="Created Date"
                >Created Date</el-option
              >
              <el-option value="updated_at" label="Updated Date"
                >Updated Date</el-option
              >
            </el-select>
            <span
              class="yale-blue mb-1 mt-1"
              style="font-size: 12px"
              v-if="selectedComponentType == 'CHART'"
            >
              NOTE: 'SELECT', 'YES OR NO', 'MULTI SELECT', 'ENTITY' fields can
              be used for chart representation
            </span>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="chartsPopUpClose" class="mr-1">Cancel</el-button>
            <el-button
              type="primary"
              :disabled="isSaveButtonDisabled"
              @click="saveChartData"
              >Save</el-button
            >
          </span>
        </dialog-component>
        <dialog-component
          :visible="configModal"
          :title="
            selectedComponentType == 'GAUGE_CHART'
              ? 'Configure Gauge Chart'
              : 'Configure Notifications'
          "
          :containerWidth="
            selectedComponentType == 'GAUGE_CHART' ? '55%' : '90%'
          "
          :containerMaxHeight="'90%'"
          @before-close="resetConfig"
        >
          <div class="mt-1 ml-1 mr-1">
            <label>Enter Component Name </label>
            <span class="red-asterisk">*</span><br />
            <el-input v-model="componentName"></el-input>
          </div>
          <div v-if="selectedComponentType == 'GAUGE_CHART'">
            <div
              class="d-flex mt-1"
              style="justify-content: space-between; padding: 0px 20px"
            >
              <div>
                <span><b>Numerator</b></span>
                <div class="mt-1">
                  <label>Entity</label><br />
                  <el-select
                    v-if="isEntityDashboard"
                    v-model="gaugeChartNumerator.relationship_id"
                    @change="isRelationshipChanged('NUMERATOR')"
                  >
                    <el-option-group label="Relationships">
                      <el-option
                        v-for="(entity, index) of getRelationshipEntities"
                        :value="entity._id"
                        :key="index"
                        :label="'My ' + entity.name"
                      >
                      </el-option>
                    </el-option-group>
                    <el-option-group label="Nested Relationships">
                      <el-option
                        v-for="(op, i) in getNestedRelationshipEntities"
                        :key="op.key + i"
                        :value="op.key"
                        :label="'My ' + op.name"
                      ></el-option>
                    </el-option-group>
                  </el-select>
                  <el-select
                    v-else
                    v-model="gaugeChartNumerator.entity_id"
                    filterable
                    clearable
                    @change="fetchGaugeChartDetails('NUMERATOR')"
                  >
                    <el-option
                      v-for="(entity, index) of getAllEntitiesData"
                      :value="entity._id"
                      :key="index"
                      :label="entity.name"
                    >
                      <span style="float: left">{{ entity.name }}</span>
                    </el-option> </el-select
                  ><br /><br />
                  <label>Filter</label><br />
                  <el-select
                    v-model="gaugeChartNumerator.filter_id"
                    filterable
                    clearable
                    :loading="numeratorFiltersLoading"
                    loading-text="Loading..."
                  >
                    <el-option
                      v-for="(filter, index) in numeratorFiters.filter(
                        (e) => !e.parent_filter_id
                      )"
                      :key="index"
                      :value="filter._id"
                      :label="filter.filter_name"
                    >
                    </el-option> </el-select
                  ><br /><br />
                  <el-radio-group v-model="gaugeChartNumerator.display_type">
                    <el-radio label="count">Count</el-radio>
                    <el-radio label="sum">Sum</el-radio>
                    <el-radio label="avg">Average</el-radio> </el-radio-group
                  ><br /><br />
                  <div v-if="gaugeChartNumerator.display_type != 'count'">
                    <label>Field</label><br />
                    <el-select
                      :loading="numeratorFieldsLoading"
                      loading-text="Loading..."
                      v-model="gaugeChartNumerator.sum_field"
                    >
                      <el-option
                        v-for="(field, index) in numeratorEntityFields"
                        :key="index"
                        :value="field.template_key"
                        :label="field.label"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>
              </div>
              <div>
                <span><b>Denominator</b></span>
                <div class="mt-1">
                  <label>Entity</label><br />
                  <el-select
                    v-if="isEntityDashboard"
                    v-model="gaugeChartDenominator.relationship_id"
                    @change="isRelationshipChanged('DENOMINATOR')"
                  >
                    <el-option-group label="Relationships">
                      <el-option
                        v-for="(entity, index) of getRelationshipEntities"
                        :value="entity._id"
                        :key="index"
                        :label="'My ' + entity.name"
                      >
                      </el-option>
                    </el-option-group>
                    <el-option-group label="Nested Relationships">
                      <el-option
                        v-for="(op, i) in getNestedRelationshipEntities"
                        :key="op.key + i"
                        :value="op.key"
                        :label="'My ' + op.name"
                      ></el-option>
                    </el-option-group>
                  </el-select>
                  <el-select
                    v-else
                    v-model="gaugeChartDenominator.entity_id"
                    filterable
                    clearable
                    @change="fetchGaugeChartDetails('DENOMINATOR')"
                  >
                    <el-option
                      v-for="(entity, index) of getAllEntitiesData"
                      :value="entity._id"
                      :key="index"
                      :label="entity.name"
                    >
                      <span style="float: left">{{ entity.name }}</span>
                    </el-option> </el-select
                  ><br /><br />
                  <label>Filter</label><br />
                  <el-select
                    v-model="gaugeChartDenominator.filter_id"
                    filterable
                    clearable
                    :loading="denominatorFiltersLoading"
                    loading-text="Loading..."
                  >
                    <el-option
                      v-for="(filter, index) in denominatorFilters.filter(
                        (e) => !e.parent_filter_id
                      )"
                      :key="index"
                      :value="filter._id"
                      :label="filter.filter_name"
                    >
                    </el-option> </el-select
                  ><br /><br />
                  <el-radio-group v-model="gaugeChartDenominator.display_type">
                    <el-radio label="count">Count</el-radio>
                    <el-radio label="sum">Sum</el-radio>
                    <el-radio label="avg">Average</el-radio> </el-radio-group
                  ><br /><br />
                  <div v-if="gaugeChartDenominator.display_type != 'count'">
                    <label>Field</label><br />
                    <el-select
                      :loading="denominatorFieldsLoading"
                      loading-text="Loading..."
                      v-model="gaugeChartDenominator.sum_field"
                    >
                      <el-option
                        v-for="(field, index) in denominatorEntityFields"
                        :key="index"
                        :value="field.template_key"
                        :label="field.label"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>
              </div>
              <div>
                <span>
                  <b>Customization</b><br />
                  <div class="mt-1">
                    <label>Delimeters</label><br />
                    <el-input
                      placeholder="Ex : 25, 50, 75"
                      v-model="gaugeChartCustomization.delemeters"
                      @input="customizationChanged"
                      style="width: 250px"
                    ></el-input
                    ><br />
                    <span>Note : Enter delimeters seperated by ","</span>
                  </div>
                  <br />
                  <label>Delimeter Colors</label> <br />
                  <div class="mt-1 d-flex">
                    <div
                      v-for="(
                        del, index
                      ) in gaugeChartCustomization.delemeterColors"
                      :key="del + '_' + index"
                      class="mr-1"
                    >
                      <el-color-picker
                        v-model="gaugeChartCustomization.delemeterColors[index]"
                        size="mini"
                      ></el-color-picker>
                      <p style="font-size: 10px">
                        {{ getDelemeterRangeLabel(index) }}
                      </p>
                    </div>
                  </div>
                </span>
              </div>
            </div>
            <div class="mb-1">
              <el-link
                :underline="false"
                style="color: #409eff"
                @click="addGaugeFilter"
                >Add Quick Filters+</el-link
              >
              <el-row
                v-for="(filter, index) in gaugeFilters"
                :key="index"
                class="mt-1"
              >
                <el-tooltip content="Select Entity">
                  <el-select
                    size="mini"
                    v-model="filter.entity_id"
                    filterable
                    clearable
                    style="width: 150px"
                  >
                    <el-option
                      v-for="(entity, index) in getAllEntitiesData"
                      :key="index"
                      :value="entity._id"
                      :label="entity.name"
                    >
                    </el-option>
                  </el-select>
                </el-tooltip>
                <el-select
                  size="mini"
                  v-model="filter.as"
                  class="ml-1"
                  style="width: 150px"
                >
                  <el-option
                    label="As Relationship"
                    value="RELATIONSHIP"
                  ></el-option>
                  <el-option
                    label="As Field"
                    value="FIELD"
                    :disabled="true"
                  ></el-option>
                </el-select>
                <span class="ml-1 mr-1">Apply on</span>
                <el-checkbox v-model="filter.numerator">Numerator</el-checkbox>
                <el-checkbox v-model="filter.denominator"
                  >Denominator</el-checkbox
                >
                <el-link
                  :underline="false"
                  style="color: #409eff"
                  @click="removeGaugeFilter(index)"
                  class="ml-1"
                  >Remove</el-link
                >
              </el-row>
            </div>
          </div>
          <div v-else class="ml-1 mt-1">
            <el-button
              type="success"
              @click="addNewNotification"
              style="margin: 10px"
              >Add New +</el-button
            >
            <el-scrollbar
              wrap-style="max-height: 300px; overflow-x: auto; overflow-y: auto; scrollbar-width:thin;"
              ><div>
                <el-table :data="notifications" size="mini" border>
                  <el-table-column label="Type">
                    <template slot-scope="scope">
                      <el-select v-model="scope.row.type" size="mini">
                        <el-option
                          value="IN_APP_NOTIFICATIONS"
                          label="App Notifications"
                        ></el-option>
                        <el-option
                          value="EVENT_NOTIFICATIONS"
                          label="Event Notifications"
                        ></el-option>
                      </el-select>
                    </template>
                  </el-table-column>
                  <el-table-column label="Entity *">
                    <template slot-scope="scope">
                      <el-select
                        v-if="isEntityDashboard"
                        v-model="scope.row.relationship_id"
                        size="mini"
                        filterable
                        clearable
                        @change="
                          isRelationshipEntityChanged(
                            scope.row.relationship_id,
                            scope.$index
                          )
                        "
                      >
                        <el-option-group label="Relationships">
                          <el-option
                            v-for="(entity, index) in getRelationshipEntities"
                            :key="index"
                            :value="entity._id"
                            :label="'My ' + entity.name"
                          >
                          </el-option>
                        </el-option-group>
                        <el-option-group label="Nested Relationships">
                          <el-option
                            v-for="(op, i) in getNestedRelationshipEntities"
                            :key="op.key + i"
                            :value="op.key"
                            :label="'My ' + op.name"
                          ></el-option>
                        </el-option-group>
                        <el-option-group label="Other Entities">
                          <el-option
                            v-for="(entity, index) in getAllEntitiesData"
                            :key="index"
                            :value="entity._id + '@OTHER_ENTITY'"
                            :label="entity.name"
                          >
                          </el-option>
                        </el-option-group>
                      </el-select>
                      <el-select
                        v-else-if="!scope.row.formbuilder_id"
                        v-model="scope.row.entity_id"
                        size="mini"
                        filterable
                        clearable
                        @change="
                          notificationEntityChanged(
                            scope.row.entity_id,
                            scope.$index
                          )
                        "
                      >
                        <el-option
                          v-for="(entity, index) in getAllEntitiesData"
                          :key="index"
                          :value="entity._id"
                          :label="entity.name"
                        >
                        </el-option>
                      </el-select>
                    </template>
                  </el-table-column>
                  <el-table-column label="Form builder *">
                    <template slot-scope="scope">
                      <el-select
                        v-if="
                          !scope.row.entity_id &&
                          scope.row.type == 'IN_APP_NOTIFICATIONS'
                        "
                        v-model="scope.row.formbuilder_id"
                        size="mini"
                        filterable
                        clearable
                        @change="
                          updateFormbuilderData(
                            scope.row.formbuilder_id,
                            scope.$index
                          )
                        "
                      >
                        <el-option
                          v-for="(formbuilder, i) in allFormbuilders"
                          :key="formbuilder._id + '_' + i"
                          :label="formbuilder.name"
                          :value="
                            formbuilder._id +
                            '#' +
                            formbuilder.name +
                            '#' +
                            formbuilder.code
                          "
                        >
                        </el-option>
                      </el-select>
                    </template>
                  </el-table-column>
                  <el-table-column label="Filter">
                    <template slot-scope="scope">
                      <el-select
                        v-if="!scope.row.formbuilder_id"
                        v-model="scope.row.filter"
                        size="mini"
                        filterable
                        clearable
                      >
                        <el-option
                          v-for="(filter, index) in (
                            entityFiltersMapping[scope.row.entity_id] || []
                          ).filter((e) => !e.parent_filter_id)"
                          :key="index"
                          :value="filter._id"
                          :label="filter.filter_name"
                        >
                        </el-option>
                      </el-select>
                    </template>
                  </el-table-column>
                  <el-table-column label="Heading *">
                    <template slot-scope="scope">
                      <el-input
                        size="mini"
                        v-model="scope.row.heading"
                      ></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column label="Events">
                    <template slot-scope="scope">
                      <el-select
                        v-if="
                          scope.row.type == 'IN_APP_NOTIFICATIONS' &&
                          scope.row.entity_id
                        "
                        v-model="scope.row.events"
                        multiple
                        size="mini"
                      >
                        <el-option label="Added" value="ADDED"></el-option>
                        <el-option label="Updated" value="UPDATED"></el-option>
                        <el-option label="Deleted" value="DELETED"></el-option>
                      </el-select>
                      <el-select
                        v-if="
                          scope.row.type == 'IN_APP_NOTIFICATIONS' &&
                          scope.row.formbuilder_id
                        "
                        v-model="scope.row.formBuilderEvents"
                        multiple
                        size="mini"
                      >
                        <el-option
                          v-for="event in allFormbuilderEvents"
                          :key="event.value"
                          :label="event.label"
                          :value="event.value"
                        >
                        </el-option>
                      </el-select>
                    </template>
                  </el-table-column>
                  <el-table-column label="Appearence">
                    <template slot-scope="scope">
                      <div class="d-flex">
                        <el-color-picker
                          size="mini"
                          v-model="scope.row.background_color"
                          class="mr-2"
                        ></el-color-picker>
                        <div @click="changeIcon(scope.$index)">
                          <icons :iconName="scope.row.icon"></icons>
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="Custom message *" width="350">
                    <template slot-scope="scope">
                      <div
                        style="border: 0.5px solid grey"
                        v-if="scope.row.entity_id && !scope.row.refresh"
                      >
                        <CustomEntityMention
                          :entity_id="scope.row.entity_id"
                          :data="scope.row"
                          :fromNotifications="true"
                        ></CustomEntityMention>
                      </div>
                      <div
                        style="border: 0.5px solid grey"
                        v-if="scope.row.formbuilder_id"
                      >
                        <CustomFormbuilderMention
                          :formbuilder_id="scope.row.formbuilder_id"
                          :data="scope.row"
                          :fromNotifications="true"
                        ></CustomFormbuilderMention>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column>
                    <template slot-scope="scope">
                      <el-button
                        size="mini"
                        circle
                        plain
                        type="danger"
                        @click="deleteNotification(scope.$index)"
                        ><i class="el-icon-delete"
                      /></el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-scrollbar>
            <span
              ><b>Hint: </b>Type @ to mention the fields in the custom
              message</span
            >
          </div>
          <span slot="footer" class="dialog-footer">
            <div>
              <el-button size="mini" @click="resetConfig" class="mr-1">
                Cancel
              </el-button>
              <el-button
                v-if="selectedComponentType == 'GAUGE_CHART'"
                type="primary"
                :disabled="disableGaugeSave()"
                @click="saveGaugeChart"
                >Save</el-button
              >
              <el-button
                v-else
                type="success"
                @click="saveNotifications"
                :disabled="disableNotificationSave()"
                >Save</el-button
              >
            </div>
          </span>
        </dialog-component>
        <dialog-component
          :title="'Select Menu Icon'"
          :visible="iconPickerdialogVisible"
          @before-close="iconPickerdialogVisible = false"
          :containerWidth="'1000px'"
        >
          <icons-list @icon-data="setIconData"></icons-list>
        </dialog-component>
      </el-col>
    </el-row>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import { bus } from "../../main";
import MobileRelatedHelper from "@/mixins/MobileRelatedHelper";
import CustomDashboardConfig from "@/mixins/CustomDashboardHelper.js";
//import EntitiesHelper from "@/mixins/EntitiesHelper";
import NavigationHelper from "@/mixins/navigationHelper";
import draggable from "vuedraggable";
import chroma from "chroma-js";
import TemplateBuilderHelper from "@/mixins/templateBuilderHelper";

// import axios from "@/config/axios";

import { fetchGlobalVariables } from "@/repo/globalVariables";
import { fetchEntityViews } from "@/repo/entityViewsRepo";
// import { fetchEntitiesByPagination } from "@/repo/entityRepo";
// import { getAllCompanyTemplatesByPagination } from "@/repo/templatesRepo";
import {
  updateCustomDashboardData,
  addSingleCustomDashboardToLocal,
} from "@/repo/customDashboardRepo";
import { fetchAllEntityFiltersByEntity } from "@/repo/filtersRepo";
import { postAPICall } from "@/helpers/httpHelper";
export default {
  mixins: [
    MobileRelatedHelper,
    CustomDashboardConfig,
    NavigationHelper,
    TemplateBuilderHelper,
  ],
  components: {
    CustomDashboardTable: () => import("./customDashboardTable.vue"),
    CustomDashboardStats: () => import("./customDashboardStats.vue"),
    CustomDashboardPieChart: () => import("./customDashboardPieChart.vue"),
    CustomDashboardFilters: () => import("./customDashboardFilters.vue"),
    CustomDashboardCalendar: () => import("./customDashboardCalendar.vue"),
    draggable,
    CustomDashboardCard: () => import("./customDashboardCard.vue"),
    CustomEntityMention: () =>
      import("@/components/widgets/entityMention/index.vue"),
    CustomFormbuilderMention: () =>
      import("@/components/widgets/entityMention/formbuilderfieldsMention.vue"),
    CustomDashboardLogins: () => import("./customDashboardLogins.vue"),
    EntityViews: () => import("@/components/entity/EntityViews.vue"),
    SchedulingEntity: () => import("./schedulerComponent.vue"),
    CustomDashboardGauge: () => import("./customDashboardGaugeChart.vue"),
    CustomDashboardNotifications: () =>
      import("./customDashboardNotifications.vue"),
    // CustomDashboardGanttChart :()=> import("./customDashboardGanttChart.vue"),
  },
  data() {
    return {
      goBackToCustom: false,
      customDashboardData: {
        allTableComponents: [],
        allStatComponents: [],
        allCustomFilters: [],
        name: "",
        includeGlobalDateFilter: false,
        allowUserChangeOrder: true,
        allMulCustomFilters: [],
        allIntegrationFilters: [],
        date_value: "",
        Custom_date_value: [],
      },
      dateFilter: false,
      ShowExport: false,
      selectedCalendarNestedRelationEntities: [],
      calendarName: "",
      rawData: null,
      centerDialogVisible: false,
      filters: [],
      selectedFilter: [],
      selectedEntityData: "",
      selectedCalendarEntities: [],
      selectedCalendarRelationEntities: [],
      selectedEntityRelationalData: "",
      filterSearchString: "",
      loading: false,
      layout: "div",
      offCanvas: true,
      windowWidth: 1500,
      refresh: false,
      expendedIndexs: [],
      loadingText: "Loading...",
      selectedComponentType: "TABLE",
      pieChartField: "",
      pieChartFieldGroupBy: "",
      sum_fields: [],
      pieChartFieldGroupByOptions: [],
      includeChartTotal: false,
      calenderDateField: [],
      chartType: "",
      pieChartFieldOptions: [],
      pieChartFieldSelectOptions: [],
      pieChartFieldEntityValues: [],
      pieChartFieldEntity: null,
      getFieldOptions: [],
      customFilterField: [],
      componentTypes: [
        {
          label: "Table",
          value: "TABLE",
        },
        {
          label: "Chart",
          value: "CHART",
        },
        // {
        //   label: "Card",
        //   value: "CARD"
        // },
        {
          label: "Custom filters",
          value: "CUSTOM_FILTER",
        },
        {
          label: "Calendar",
          value: "CALENDAR",
        },
        {
          label: "Logins",
          value: "LOGINS",
        },
        {
          label: "Entity Views",
          value: "ENTITY_VIEW",
        },
        {
          label: "Scheduling",
          value: "SCHEDULING",
        },
        {
          label: "Gauge Chart",
          value: "GAUGE_CHART",
        },
        {
          label: "Notifications",
          value: "NOTIFICATIONS",
        },
        {
          label: "Multiple Custom filters",
          value: "MULTIPLE_CUSTOM_FILTER",
        },
      ],
      chartTypes: [
        {
          label: "Pie",
          value: "PIE",
        },
        {
          label: "Bar",
          value: "BAR",
        },
        {
          label: "Doughnut",
          value: "DOUGHNUT",
        },
        {
          label: "Line",
          value: "LINE",
        },
        {
          label: "Combination",
          value: "COMBINATION",
        },
      ],
      getTemplateFields: [],
      getTemplateDateFields: [],
      globalVariablesData: [],
      tableData: [],
      isEntityDashboard: false,
      permittedEntities: [],
      permittedRelatedEntities: [],
      permittedNestedRelatedEntities: [],
      relationshipEntities: [],
      allEntityFilters: [],
      getTotalFields: [],
      allFormbuilderEvents: [
        {
          value: "FILLED",
          label: "Filled",
        },
        {
          value: "APPROVED",
          label: "Approved",
        },
        {
          value: "REJECTED",
          label: "Rejected",
        },
      ],
      pieChartFieldGroupBySelectedOptions: [],
      selectedValues: [],
      groupByFieldEntityValues: [],
      groupByFieldEntity: null,
      chartFilters: [],
      chartSelectedFilter: "",
      tableFieldGroupBy: "",
      tableFieldSubGroupBy: "",
      tableFieldGroupByFields: [
        {
          key: "count",
          name: "Count",
          type: "count",
          sub_group: false,
        },
      ],
      tableFieldGroupByFieldsModal: [],
      openTableColumsModal: false,
      tableFieldGroupBySelectedOptions: [],
      allTemplates: [],
      allowOtherShowData: false,
      // getAllCompanyTemplatesData: null,
      centerLoginsDialogVisible: false,
      actionOptions: [
        { label: "Login", value: "login" },
        { label: "Logout", value: "logout" },
        { label: "Duration", value: "duration" },
        // Add more options as needed
      ],
      info: [
        { component: "Date", field: "" },
        { component: "Login", field: "" },
        { component: "Logout", field: "" },
        { component: "Duration", field: "" },
      ],
      allEntityFields: [],
      allDateTimeFields: [],
      selectedLoginEntity: "",
      selectedLoginEntityRelationalData:"",
      selectedEntityView: "",
      isRelationalData: false,
      loginsDataDetails: [],
      getTemplateTimeFields: [],
      start_button: "",
      end_button: "",
      isloginIndex: [],
      Days: "",
      shouldDisplayInput: false,
      displayAutoLogout: false,
      user_field: "",
      entityField: [],
      auto_logout: "",
      selectedFieldType: "",
      dateTimeFieldObject: null,
      fieldSelected: false,
      selectedFirstField: null,
      refreshComponents: false,
      textField: [],
      companyuser_field: "",
      getAllEntities: null,
      getAllEntityViews: null,
      //scheduler variables
      resourceEntity: "",
      jobEntity: "",
      schedulerEntity: "",
      applyRelationship: false,
      schedulerEntityDateField: "",
      jobEntityTimeField: "",
      resourceEntityTimeField: "",
      schedulerEntitySubGroups: [],
      entityFilterObject: {},
      schedulerComponentName: "",
      rows: [{ sum_field: null, chart_type: null }],
      combinedData: [],
      subChartTypes: [
        {
          label: "Line",
          value: "line",
        },
        {
          label: "Bar",
          value: "bar",
        },
      ],
      centerChartsDialogVisible: false,
      componentName: "",
      gaugeChartNumerator: {
        display_type: "count",
      },
      gaugeChartDenominator: {
        display_type: "count",
      },
      numeratorFiters: [],
      denominatorFilters: [],
      numeratorEntityFields: [],
      denominatorEntityFields: [],
      numeratorFiltersLoading: false,
      denominatorFiltersLoading: false,
      configModal: false,
      numeratorFieldsLoading: false,
      denominatorFieldsLoading: false,
      editIndex: -1,
      gaugeFilters: [],
      notifications: [],
      entityFiltersMapping: {},
      iconPickerdialogVisible: false,
      iconEditIndex: -1,
      gaugeChartCustomization: {
        delemeters: "",
        delemeterColors: [],
      },
      chartComponentName: "",
      BarColor: "",
      schedulingEntityFields: [],
      jobEntityFields: [],
      resourceEntityFields: [],
      integrations: [],
      predefinedIntegrations: [],
      isStacked: false,
      multipleCustomFilterField: "",
      selectedMulCustomFilterEntity: [],
      entityOptions: [],
      matchingFieldKeys: [],
      orderedMatchingFieldKeys: [],
      multipleFilterDependencies: [],
      multipleFilterDependency: "",
      schedulerEntitySubGroupsFilters: [],
      schedulerEntitySubGroupsData: [],
      getAllDateFields: [],
      date_filter_field: "",
      allFormbuilders: [],
    };
  },
  computed: {
    ...mapGetters("entities", [
      // "getAllEntities",
      "getEntityDataByEntityId",
      "geCustomDashboardPieStats",
      "getEntityRecordsForTable",
      "getChildEntityDataById",
    ]),
    ...mapGetters("filters", [
      "getAllEntityFilters",
      "getCreateNewFilter",
      "getUpdateFilterStatus",
      "getDeleteFilterStatus",
    ]),
    ...mapGetters("customDashboard", [
      "getSingleDashboardData",
      "getUpdateDashboardStatus",
      "getUpdateDashboardData",
      "getUpdateDashboardError",
      "getCreateNewDashboard",
      "getCreateNewDashboardError",
    ]),
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getActiveWorkspace",
      "getAuthenticationStatus",
      "getWorkspaceList",
      "getUserType",
      "getSwitchWorkspaceError",
      "getPermissionList",
      "getPermissions",
      "getGlobalFiltervalue",
      "getAuthenticationDetails",
      "getUserTypeList",
      "getUserTypeEmailsErrors",
      "getUserTypeEmails",
    ]),
    ...mapGetters("company", [
      "getCompanyDetails",
      "getUpdateCompany",
      "getCompanyLogo",
      "getSingleCompanyLogo",
      "getContactLoginSlug",
    ]),
    ...mapGetters("navigationOpen", [
      "getIsOpen",
      "getIsCollapse",
      "getIsMobile",
    ]),
    ...mapGetters("companyTemplates", ["getAllCompanyTemplatesData"]),
    ...mapGetters("globalVariables", ["getAllGlobalVariables"]),
    ...mapGetters("applicationUsersPermission", [
      "getCreatePermissionStatus",
      "getEntityPermission",
      "getUpdatePermissionStatus",
    ]),
    ...mapGetters("entityRelationships", ["getEntityRelationships"]),
    ...mapGetters("sso", ["getAuthenticatedSSOToken"]),
    // ...mapGetters("entityViews", ["getAllEntityViews"]),
    getAllEntitiesData() {
      if (this.getAllEntities && this.getAllEntities?.data) {
        if (this.isEntityDashboard) {
          return (this.getAllEntities.data || []).filter(
            (e) => this.permittedEntities.indexOf(e._id) != -1
          );
        }
        return this.getAllEntities.data;
      }
      return [];
    },
    getNestedRelationshipEntities() {
      if (this.getAllEntities?.data) {
        if (
          this.getEntityPermission?._id &&
          this.permittedNestedRelatedEntities &&
          this.permittedNestedRelatedEntities.length
        ) {
          return this.permittedNestedRelatedEntities.map((rl) => {
            let [e1, e2] = rl.split("#");
            let entity1 = this.getAllEntities.data.find((e) => e._id == e1);
            let entity2 = this.getAllEntities.data.find((e) => e._id == e2);
            if (entity1?.name && entity2?.name) {
              return {
                name: entity1.name + " / " + entity2.name,
                key: rl,
              };
            }
          });
        }
        return [];
      }
      return [];
    },
    getRelationshipEntities() {
      if (this.getAllEntities?.data) {
        if (this.getEntityPermission?._id) {
          return this.getAllEntities.data.filter(
            (e) => this.permittedRelatedEntities.indexOf(e._id) != -1
          );
        }
        if (this.relationshipEntities && this.relationshipEntities.length) {
          return this.getAllEntities.data.filter(
            (e) => this.relationshipEntities.indexOf(e._id) != -1
          );
        }
        return [];
      }
      return [];
    },
    getCurrentEntitiesData() {
      let currentEntities = [];
      if (this.customDashboardData) {
        if (
          this.customDashboardData.allTableComponents &&
          this.customDashboardData.allTableComponents.length
        ) {
          currentEntities = [
            ...currentEntities,
            ...this.customDashboardData.allTableComponents.flatMap(
              (e) => e.entity_id
            ),
          ];
        }
        if (
          this.customDashboardData.allStatComponents &&
          this.customDashboardData.allStatComponents.length
        ) {
          currentEntities = [
            ...currentEntities,
            ...this.customDashboardData.allStatComponents.flatMap(
              (e) => e.entity_id
            ),
          ];
        }
      }
      return this.getAllEntities && this.getAllEntities.data
        ? this.getAllEntities.data.filter(
            (e) => currentEntities.indexOf(e._id) !== -1
          )
        : [];
    },
    getCurrentEntitiesFields() {
      let entities = this.getCurrentEntitiesData;
      const templateFields = [];

      entities.forEach((entityObject) => {
        const id = entityObject._id;
        let entity = this.getAllEntitiesData.find((e) => e._id === id);
        if (!entity) {
          entity = this.getRelationshipEntities.find((e) => e._id === id);
        }
        const templateIds = (entity.templates || [])
          .filter((e) => !e.is_repeatable)
          .map((e) => e.template_id);
        const entityTemplates = this.allTemplates.filter((e) =>
          templateIds.includes(e._id)
        );
        entityTemplates.forEach((e) => {
          const data = JSON.parse(JSON.stringify(e));
          const fieldTypes = ["ENTITY"];
          const fields = this.getTemplateFieldsByType(data, fieldTypes);

          templateFields.push(...fields);
        });
      });
      return templateFields;
    },
    checkIsDisabled() {
      if (this.selectedComponentType == "GAUGE_CHART") {
        if (
          !this.gaugeChartNumerator.entity_id ||
          !this.gaugeChartDenominator.entity_id
        ) {
          return true;
        }
        return false;
      } else if (this.selectedComponentType == "NOTIFICATIONS") {
        if (
          this.componentName == "" ||
          this.notifications.filter((n) => !n.entity_id && !n.formbuilder_id)
            .length
        ) {
          return true;
        }
        return false;
      } else if (this.selectedComponentType == "CHART") {
        if (!this.chartType) {
          return true;
        }
        return false;
      } else if (this.selectedComponentType == "LOGINS") {
        return !this.loginsDataDetails.length;
      } else if (this.selectedComponentType == "SCHEDULING") {
        return (
          !this.resourceEntity ||
          !this.jobEntity ||
          !this.schedulerEntity ||
          !this.schedulerComponentName ||
          !this.schedulerEntityDateField
        );
      } else if (this.selectedComponentType == "ENTITY_VIEW") {
        return this.selectedEntityView == "";
      } else if (this.selectedComponentType == "MULTIPLE_CUSTOM_FILTER") {
        return (
          !this.multipleCustomFilterField || !this.selectedMulCustomFilterEntity
        );
      } else {
        if (this.selectedComponentType == "CALENDAR") {
          return (
            !this.calenderDateField.length ||
            (!this.selectedCalendarEntities.length &&
              !this.selectedCalendarRelationEntities.length &&
              !this.selectedCalendarNestedRelationEntities.length)
          );
        }
        return (
          !this.selectedComponentType ||
          (!this.selectedEntityData &&
            !this.selectedEntityRelationalData &&
            (!this.selectedCalendarEntities ||
              !this.selectedCalendarEntities.length)) ||
          // (this.selectedComponentType == "TABLE" &&
          // !this.selectedFilter.length &&
          // !this.selectedEntityRelationalData) ||
          (this.selectedComponentType == "CALENDAR" &&
            this.calenderDateField.length == 0)
        );
      }
    },
    disableSave() {
      if (this.tableData.length == 0) {
        return true;
      }
      let calendarname = this.calendarName;
      if (calendarname.length == 0) {
        return true;
      }
      let empty = this.tableData.find(
        (e) =>
          !e.field ||
          !e.custom_message ||
          (typeof e.custom_message == "object" &&
            Object.keys(e.custom_message).length == 0) ||
          !e?.custom_message?.content[0]?.content
      );
      if (empty) {
        return true;
      }
      return false;
    },
    disableloginSave() {
      if (this.info.length == 0) {
        return true;
      }
      let empty = this.info.find((e) => !e.field);
      if (empty || this.start_button == "" || this.end_button == "") {
        return true;
      }
      if (this.shouldDisplayInput == true) {
        if (this.Days == "") {
          return true;
        } else {
          return false;
        }
      }
      return false;
    },
    checkTableGroupByField() {
      if (
        this.tableFieldGroupByFieldsModal &&
        this.tableFieldGroupByFieldsModal.length
      ) {
        let empty = this.tableFieldGroupByFieldsModal.find(
          (e) => !e || !e.name || !e.key
        );
        return empty ? true : false;
      }
      return (
        !this.tableFieldGroupByFieldsModal ||
        !this.tableFieldGroupByFieldsModal.length
      );
    },
    isEntitySelectDisabled() {
      if (this.selectedEntityRelationalData ) {
        return true;
      } else {
        return false;
      }
    },
    isEntitySelectDisabledForLogin(){
      if (this.selectedLoginEntityRelationalData ) {
        return true;
      } else {
        return false;
      }
    },
    isRelationalSelectDisabled() {
      if (this.selectedLoginEntity) {
        return true;
      } else {
        return false;
      }
    },
    isSaveButtonDisabled() {
      return (
        !this.chartComponentName.trim() ||
        !this.chartType ||
        !this.pieChartField
      );
    },
    getPredefinedApplications() {
      return (this.integrations || []).filter(
        (e) => e.app_type == "PRE_DEFINED"
      );
    },
  },
  async backToCustom() {
    let query = this.getNavigationQuery(this.$route.query);
    let UrlPath = "";
    UrlPath = "/custom/";
    this.$router.push({
      path: UrlPath,
      query: query,
    });
    this.goBackToCustom = false;
  },
  async mounted() {
    if (this.getIsMobile) {
      this.$message.warning("dashboard edit is not allowed in small screens");
      this.$router.push({ path: "/custom/dashboard-list" });
    }
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    if (this.$route.name == "entity-custom-dashboard-config") {
      this.isEntityDashboard = true;
    }
    await this.getNecessaryInfo();
    await this.checkUrlType();
    this.getCompanyInfo();
    this.allFormbuilders = await this.fetchAllFormbuilders();
  },
  watch: {
    selectedMulCustomFilterEntity() {
      this.updateMatchingFieldKeys();
    },
  },
  methods: {
    updateFormbuilderData(formbuilderId, index) {
      let selectedFormbuilder = this.allFormbuilders.find(
        (f) => f._id == formbuilderId.split("#")[0]
      );
      this.notifications[index].is_anonymous =
        selectedFormbuilder?.form_builders_owner == "ANONYMOUS" ? true : false;
    },
    async fetchSubGroupFilters() {
      this.schedulerEntitySubGroupsData = [];
      await Promise.all(
        this.schedulerEntitySubGroups.map(async (fl) => {
          let field = this.schedulingEntityFields.find(
            (e) => e.template_key == fl
          );
          if (field?.entity_id) {
            let filters =
              this.entityFiltersMapping[field.entity_id] ||
              (await fetchAllEntityFiltersByEntity({
                entityId: field.entity_id,
              }));
            this.entityFiltersMapping[field.entity_id] = filters;
            this.schedulerEntitySubGroupsData.push({
              label: field.label,
              entity_id: field.entity_id,
              filters: filters,
              key: fl,
            });
          }
        })
      );
    },
    checkAndAddGlobalFilters(stats) {
      let filters = [];

      stats.forEach((stat) => {
        if (
          stat?.apply_global_filter &&
          stat?.global_filters &&
          stat.global_filters.length
        ) {
          let integration = this.getPredefinedApplications.find(
            (e) => e?._id == stat?.integration_id
          );

          stat.global_filters.forEach((gl) => {
            let existedFilter = filters.find(
              (fl) =>
                fl.integration_id == stat.integration_id && fl?.dependency == gl
            );

            if (!existedFilter && integration?.connection_id) {
              filters.push({
                connection_id: integration.connection_id,

                dependency: gl,

                default_value: integration.dependencies?.[gl] || "",

                appName: integration.application,

                type: "INTEGRATION_FILTER",
                integration_id: stat.integration_id,
              });
            }
          });
        }
      });

      this.customDashboardData["allIntegrationFilters"] = [...filters];
    },
    getSchedulingEntityIds(types, property) {
      return this.schedulingEntityFields
        .filter((e) => types.includes(e.input_type))
        .flatMap((e) => e[property]);
    },
    getEntityFields(entity_id, type) {
      console.log("entity_id", entity_id);
      let selectedEntity = this.getAllEntitiesData.find(
        (e) => e._id == entity_id
      );
      let fields = [];
      (selectedEntity || []).templates.forEach((t) => {
        let temp = this.allTemplates.find((e) => e._id == t.template_id);
        fields = [
          ...fields,
          ...(temp.sections?.[0]?.fields || []).map((f) => {
            f.template_key = temp._id + "#" + f.key;
            return f;
          }),
        ];
      });
      if (type == "RESOURCE") {
        this.resourceEntityFields = [...fields];
      } else if (type == "JOB") {
        this.jobEntityFields = [...fields];
      } else {
        this.schedulingEntityFields = [...fields];
      }
    },
    resetConfig() {
      this.gaugeChartNumerator = {
        display_type: "count",
      };
      this.gaugeChartDenominator = {
        display_type: "count",
      };
      this.gaugeChartCustomization = {
        delemeters: "33, 66",
        delemeterColors: ["#f56c6c", "#e6a23c", "#67c23a"],
      };
      this.gaugeFilters = [];
      this.notifications = [];
      this.componentName = "";
      this.editIndex = -1;
      this.configModal = false;
    },
    async isRelationshipEntityChanged(entity_id, index) {
      let entityId = entity_id.includes("#")
        ? entity_id.split("#")[1]
        : entity_id.includes("@")
        ? entity_id.split("@")[0]
        : entity_id;
      this.notifications[index].entity_id = entityId;
      await this.notificationEntityChanged(entityId, index);
    },
    async isRelationshipChanged(type) {
      switch (type) {
        case "NUMERATOR":
          this.gaugeChartNumerator.entity_id =
            this.gaugeChartNumerator.relationship_id.includes("#")
              ? this.gaugeChartNumerator.relationship_id.split("#")[1]
              : this.gaugeChartNumerator.relationship_id;
          await this.fetchGaugeChartDetails("NUMERATOR");
          break;
        case "DENOMINATOR":
          this.gaugeChartDenominator.entity_id =
            this.gaugeChartDenominator.relationship_id.includes("#")
              ? this.gaugeChartDenominator.relationship_id.split("#")[1]
              : this.gaugeChartDenominator.relationship_id;
          await this.fetchGaugeChartDetails("DENOMINATOR");
          break;
      }
    },
    getDelemeterRangeLabel(index) {
      let delemeters = this.gaugeChartCustomization.delemeters.split(",");
      switch (index) {
        case 0:
          return `0 - ${delemeters[index]}`;
        case delemeters.length:
          return `${delemeters[index - 1]} - 100`;
        default:
          return `${delemeters[index - 1]} - ${delemeters[index]}`;
      }
    },
    customizationChanged() {
      let splitArrayLength =
        this.gaugeChartCustomization.delemeters.split(",").length;
      if (this.gaugeChartCustomization.delemeters == "") {
        splitArrayLength = 0;
      }
      const desiredArrayLength = splitArrayLength + 1;
      while (
        this.gaugeChartCustomization.delemeterColors.length > desiredArrayLength
      ) {
        this.gaugeChartCustomization.delemeterColors.pop();
      }
      while (
        this.gaugeChartCustomization.delemeterColors.length < desiredArrayLength
      ) {
        this.gaugeChartCustomization.delemeterColors.push("#ffffff");
      }
    },
    changeIcon(index) {
      this.iconEditIndex = index;
      this.iconPickerdialogVisible = true;
    },
    setIconData(selectedIcon) {
      this.notifications[this.iconEditIndex].icon = selectedIcon;
      this.iconPickerdialogVisible = false;
    },
    async editNotifications(index) {
      this.selectedComponentType = "NOTIFICATIONS";
      this.configModal = true;
      this.editIndex = index;
      let component = this.customDashboardData.allTableComponents[index];
      this.notifications = [...component.notifications];
      this.componentName = component.component_name;
      await Promise.all(
        this.notifications.map((n, i) =>
          this.notificationEntityChanged(n.entity_id, i)
        )
      );
    },
    async notificationEntityChanged(entityId, index) {
      this.notifications[index].refresh = true;
      if (!this.entityFiltersMapping?.[entityId]?.length) {
        let filters = await fetchAllEntityFiltersByEntity({
          entityId: entityId,
        });
        this.entityFiltersMapping[entityId] = [...filters];
      }
      this.notifications[index].refresh = false;
    },
    deleteNotification(index) {
      this.notifications.splice(index, 1);
    },
    addNewNotification() {
      this.notifications.push({
        type: "IN_APP_NOTIFICATIONS",
        entity_id: "",
        formbuilder_id: "",
        events: ["ADDED", "UPDATED", "DELETED"],
        formBuilderEvents: ["FILLED", "APPROVED", "REJECTED"],
        filter: "",
        keyIndex: this.getRandomKey(),
        refresh: false,
        custom_message: "",
        background_color: "#FFFFFF",
        icon: "dashboard",
        heading: "",
        is_anonymous: false,
      });
    },
    addGaugeFilter() {
      this.gaugeFilters.push({
        type: "ENTITY",
        entity_id: "",
        as: "RELATIONSHIP",
        numerator: true,
        denominator: true,
      });
    },
    removeGaugeFilter(index) {
      this.gaugeFilters.splice(index, 1);
    },
    disableNotificationSave() {
      if (
        this.componentName == "" ||
        !this.notifications.length ||
        this.notifications.filter(
          (n) =>
            (n.entity_id == "" && n.formbuilder_id == "") ||
            n.heading == "" ||
            n.custom_message == ""
        ).length ||
        this.notifications.filter(
          (n) =>
            (n.type == "IN_APP_NOTIFICATIONS" &&
              !n.events.length &&
              !n.formBuilderEvents.length) ||
            (n.type == "EVENT_NOTIFICATIONS" && n.filter == "")
        ).length
      ) {
        return true;
      }
      return false;
    },
    disableGaugeSave() {
      if (
        this.gaugeChartNumerator.entity_id == "" ||
        this.gaugeChartDenominator.entity_id == "" ||
        this.componentName == "" ||
        (this.gaugeChartNumerator.display_type != "count" &&
          this.gaugeChartNumerator.sum_field == "") ||
        (this.gaugeChartDenominator.display_type != "count" &&
          this.gaugeChartDenominator.sum_field == "")
      ) {
        return true;
      }
      return false;
    },
    saveNotifications() {
      if (this.editIndex > -1) {
        this.refreshComponents = true;
        this.customDashboardData.allTableComponents[this.editIndex] = {
          ...this.customDashboardData.allTableComponents[this.editIndex],
          notifications: this.notifications,
          component_name: this.componentName,
        };
        setTimeout(() => {
          this.refreshComponents = false;
        }, 500);
        this.resetConfig();
      }
      this.configModal = false;
    },
    saveGaugeChart() {
      if (this.editIndex > -1) {
        this.refreshComponents = true;
        this.customDashboardData.allTableComponents[this.editIndex] = {
          ...this.customDashboardData.allTableComponents[this.editIndex],
          ...{
            numerator: this.gaugeChartNumerator,
            denominator: this.gaugeChartDenominator,
            component_name: this.componentName,
            filters: this.gaugeFilters,
            customization: this.gaugeChartCustomization,
          },
        };
        this.resetConfig();
        setTimeout(() => {
          this.refreshComponents = false;
        }, 500);
      }
      this.configModal = false;
    },
    async editGauge(index) {
      this.selectedComponentType = "GAUGE_CHART";
      this.configModal = true;
      this.editIndex = index;
      let component = this.customDashboardData.allTableComponents[index];
      this.gaugeChartNumerator = { ...component.numerator };
      this.gaugeChartDenominator = { ...component.denominator };
      this.componentName = component.component_name;
      this.gaugeFilters = component?.filters || [];
      this.gaugeChartCustomization = component?.customization || {
        delemeters: "33, 66",
        delemeterColors: ["#f56c6c", "#e6a23c", "#67c23a"],
      };
      await Promise.all([
        this.fetchGaugeChartDetails("NUMERATOR"),
        this.fetchGaugeChartDetails("DENOMINATOR"),
      ]);
    },
    async fetchGaugeChartDetails(type) {
      await Promise.all([
        this.fetchGaugeEntityFields(type),
        this.fetchGaugeFilters(type),
      ]);
    },
    async fetchGaugeEntityFields(type) {
      let fields;
      if (type == "NUMERATOR") {
        this.numeratorFieldsLoading = true;
        this.numeratorEntityFields = [];
        if (this.gaugeChartNumerator?.entity_id != "") {
          fields = await this.fetchEntityDetails(
            this.gaugeChartNumerator.entity_id,
            true
          );
          this.numeratorEntityFields = [
            ...fields.filter(
              (fd) =>
                ["NUMBER", "CURRENCY", "AGGREGATE_FUNCTION"].includes(
                  fd.inputType
                ) || ["NUMBER, CURRENCY"].includes(fd.result_type)
            ),
          ];
        }
        this.numeratorFieldsLoading = false;
      } else if (type == "DENOMINATOR") {
        this.denominatorFieldsLoading = true;
        this.denominatorEntityFields = [];
        if (this.gaugeChartDenominator?.entity_id != "") {
          fields = await this.fetchEntityDetails(
            this.gaugeChartDenominator.entity_id,
            true
          );
          this.denominatorEntityFields = [
            ...fields.filter(
              (fd) =>
                ["NUMBER", "CURRENCY", "AGGREGATE_FUNCTION"].includes(
                  fd.inputType
                ) || ["NUMBER, CURRENCY"].includes(fd.result_type)
            ),
          ];
        }
        this.denominatorFieldsLoading = false;
      }
    },
    async fetchGaugeFilters(type) {
      let filters;
      if (type == "NUMERATOR") {
        this.numeratorFiltersLoading = true;
        this.numeratorFiters = [];
        if (this.gaugeChartNumerator?.entity_id != "") {
          if (
            this.entityFiltersMapping?.[this.gaugeChartNumerator.entity_id]
              ?.length
          ) {
            filters = [
              ...this.entityFiltersMapping[this.gaugeChartNumerator.entity_id],
            ];
          } else {
            filters = await fetchAllEntityFiltersByEntity({
              entityId: this.gaugeChartNumerator.entity_id,
            });
            this.entityFiltersMapping[this.gaugeChartNumerator.entity_id] = [
              ...filters,
            ];
          }
          this.numeratorFiters = [...filters];
        }
        this.numeratorFiltersLoading = false;
      } else if (type == "DENOMINATOR") {
        this.denominatorFiltersLoading = true;
        this.denominatorFilters = [];
        if (this.gaugeChartDenominator?.entity_id != "") {
          if (
            this.entityFiltersMapping?.[this.gaugeChartDenominator.entity_id]
              ?.length
          ) {
            filters = [
              ...this.entityFiltersMapping[
                this.gaugeChartDenominator.entity_id
              ],
            ];
          } else {
            filters = await fetchAllEntityFiltersByEntity({
              entityId: this.gaugeChartDenominator.entity_id,
            });
            this.entityFiltersMapping[this.gaugeChartDenominator.entity_id] = [
              ...filters,
            ];
          }
          this.denominatorFilters = [...filters];
        }
        this.denominatorFiltersLoading = false;
      }
    },
    backToCustom() {
      this.$router.go(-1);
      this.goBackToCustom = false;
    },
    async fetchEntityDetails(
      id,
      includeLabel = false,
      includeSlugKey = false,
      include_data_tables = false,
      ogLabel = false
    ) {
      await this.$store.dispatch("entities/fetchChildEntityById", id);
      let allFields = [];
      if (
        this.getChildEntityDataById &&
        this.getChildEntityDataById.templates
      ) {
        this.getChildEntityDataById.templates.forEach((template) => {
          if (template?.templateInfo?.sections[0]?.fields) {
            allFields = [
              ...allFields,
              ...this.getTemplateFieldsInfo(
                template?.templateInfo,
                includeLabel,
                includeSlugKey,
                include_data_tables,
                ogLabel
              ),
            ];
          }
        });
        return allFields;
      }
    },
    async getCompanyInfo() {
      this.loading = true;
      try {
        await this.$store.dispatch(
          "company/fetchCompany",
          this.getActiveWorkspace.company_id
        );
        if (this.getCompanyDetails) {
          this.brandingInfo = {
            ...this.getCompanyDetails,
          };
          await Promise.all([this.applyTheme()]);
          this.loading = false;
        }
      } catch (e) {
        this.loading = false;
        this.$notify.error({
          title: "Error",
          message: e,
        });
      }
    },
    async applyTheme() {
      // Apply theme using company info
      // Ensure that this.companyInfo is set before applying theme
      document.documentElement.style.setProperty(
        "--primary-color",
        this.getCompanyDetails.dialog_header_color
      );
      document.documentElement.style.setProperty(
        "--primary-contrast-color",
        this.getCompanyDetails.dialog_header_text_color
      );
      document.documentElement.style.setProperty(
        "--sidemenu-background-color",
        this.getCompanyDetails.sidemenu_background
      );
      document.documentElement.style.setProperty(
        "--sidemenu-text-color",
        this.getCompanyDetails.sidemenu_text
      );
      const primaryColor = getComputedStyle(document.documentElement)
        .getPropertyValue("--primary-color")
        .trim();
      const lighterPrimaryColor = chroma(primaryColor).brighten(0.5).hex();
      const darkerPrimaryColor = chroma(primaryColor).darken(0.5).hex();
      document.documentElement.style.setProperty(
        "--darker-primary-color",
        darkerPrimaryColor
      );
      document.documentElement.style.setProperty(
        "--lighter-primary-color",
        lighterPrimaryColor
      );
      // document.documentElement.style.setProperty('--Hover-color-inputfield', this.companyInfo.menubackground);
      // document.documentElement.style.setProperty('--text-secondary-color', 'white');

      const secondaryColor = getComputedStyle(document.documentElement)
        .getPropertyValue("--secondary-color")
        .trim();

      const darkerSecondaryColor = chroma(secondaryColor).brighten(0.3).hex();

      document.documentElement.style.setProperty(
        "--darker-secondary-color",
        darkerSecondaryColor
      );
    },
    getTemplateFieldsInfo(
      data,
      includeLabel,
      includeSlugKey = false,
      include_data_tables = false,
      ogLabel = false
    ) {
      let fields = [];
      data.sections.forEach((e) => {
        fields = [...fields, ...e.fields];
      });

      fields = fields.map((e) => {
        e.template_id = data._id;
        e.template_key = data._id + "#" + e.key;
        e.template_name = data.name;
        if (includeLabel) {
          e.label = data.name + " - " + e.label;
        } else if (!ogLabel) {
          e.label = data.slug + "#" + e.key;
        }
        if (includeSlugKey) {
          e.key = data.slug + "_" + e.key;
        }
        return e;
      });
      if (include_data_tables) {
        //have to check data table fields
        let dataTableFields = fields.filter(
          (e) => e?.inputType == "DATA_TABLE"
        );
        if (dataTableFields && dataTableFields.length) {
          dataTableFields.forEach((fl) => {
            if (fl?.data_table_columns) {
              fields = [
                ...fields,
                ...fl?.data_table_columns.map((e) => {
                  e.data_table_key = fl.key;
                  e.template_id = data._id;
                  e.label = fl.label + "-" + e.label;
                  e.all_duplicate_rules = fl.all_duplicate_rules;
                  e.duplicate_query_type = fl.duplicate_query_type;
                  if (
                    fl.input_type == "ENTITY_VARIABLE" &&
                    fl?.relationship_key
                  ) {
                    e.table_relationship_key = fl.relationship_key;
                  }
                  if (includeLabel) {
                    e.label = data.name + " - " + e.label;
                  } else {
                    e.label = data.slug + "#" + e.key;
                  }
                  if (includeSlugKey) {
                    e.key = data.slug + "_" + e.key;
                  }
                  e.template_id = data._id;
                  e.template_key = data._id + "#" + e.key;
                  e.template_name = data.name;
                  e["isDataTableField"] = true;
                  // if (include_data_table_key) {
                  //   e.key = e.data_table_key + "/" + e.key;
                  // }
                  return e;
                }),
              ];
            }
          });
        }
        fields = fields.filter((e) => {
          return e.inputType != "DATA_TABLE";
        });
      }
      return fields;
    },
    openTableColumsModalClose() {
      this.openTableColumsModal = false;
    },
    isActionDisabled(index, selectedAction) {
      if (index > 0 && this.tableDatas[index - 1].action === selectedAction) {
        return true;
      }
      return false;
    },
    isFieldDisabled(field) {
      let fieldSelected = false;
      let selectedFirstField;
      this.info.map((e) => {
        if (e.field) {
          fieldSelected = true;
          selectedFirstField = e;
        }
      });
      if (!fieldSelected) {
        this.selectedFieldType = "";
        this.dateTimeFieldObject = null;
        this.shouldDisplayInput = false;
        return false;
      }
      if (!this.dateTimeFieldObject) {
        let key = selectedFirstField.field;
        this.dateTimeFieldObject = this.allDateTimeFields.find(
          (e) => e.template_key == key
        );
        if (
          this.dateTimeFieldObject &&
          this.dateTimeFieldObject.isDataTableField
        ) {
          this.selectedFieldType = "data_table";
          this.shouldDisplayInput = true;
          this.displayAutoLogout = false;
        } else {
          this.selectedFieldType = "normal";
          this.shouldDisplayInput = false;
          this.displayAutoLogout = true;
        }
      }

      if (!this.dateTimeFieldObject) {
        return false;
      }
      if (
        this.selectedFieldType == "data_table" &&
        field.isDataTableField &&
        this.info.findIndex((ex) => ex.field == field.template_key) == -1
      ) {
        return false;
      } else if (
        this.selectedFieldType == "normal" &&
        !field.isDataTableField &&
        this.info.findIndex((ex) => ex.field == field.template_key) == -1
      ) {
        return false;
      } else {
        return true;
      }
    },
    async addSubGroupCount() {
      if (this.tableFieldSubGroupBy) {
        this.tableFieldGroupByFields.push({
          key: "count",
          name: "Count",
          type: "count",
          sub_group: true,
        });
        await this.getSelectedFieldOptions(this.tableFieldSubGroupBy);
      }
    },
    resetEventModal() {
      this.openNewEventLoading = false;
      this.openNewEventModal = false;
      this.tableData = [];
      this.calenderDateField = [];
      this.centerDialogVisible = false;
    },
    CenterDialogVisibleClose() {
      this.centerDialogVisible = false;
    },
    loginPopUpClose() {
      this.centerLoginsDialogVisible = false;
    },
    saveTableGroupBy() {
      this.tableFieldGroupByFields = [];
      this.tableFieldGroupByFields = [...this.tableFieldGroupByFieldsModal];
      this.openTableColumsModal = false;
    },
    fillLabel(key, index) {
      if (key) {
        if (key == "count") {
          this.tableFieldGroupByFieldsModal[index]["name"] = "Count";
        } else {
          let field = this.getTotalFields.find((e) => e.key == key),
            group_by_fields = ["SELECT", "ENTITY", "MULTI_SELECT"];
          this.tableFieldGroupByFieldsModal[index]["type"] =
            field && group_by_fields.indexOf(field.inputType) != -1
              ? "groupby"
              : "sum";
          this.tableFieldGroupByFieldsModal[index]["name"] =
            field && field.label ? field.label : "";
        }
      }
    },
    addNewColumn() {
      this.tableFieldGroupByFieldsModal.push({
        name: "",
        key: "",
        type: "",
      });
    },
    openTableColums() {
      this.tableFieldGroupByFieldsModal = [];
      this.tableFieldGroupByFieldsModal = [...this.tableFieldGroupByFields];
      this.openTableColumsModal = true;
    },
    getCurrenEntityFilter(id) {
      if (id) {
        let found = this.allEntityFilters.find((e) => e.entity_id == id);
        if (found) {
          return found.filters;
        }
        return [];
      }
      return [];
    },
    getEntityFilters(row) {
      if (
        row &&
        row.includes("#") &&
        row.split("#")[0] &&
        !this.allEntityFilters[row.split("#")[0]]
      ) {
        let field = this.getTemplateDateFields.find((e) => e.key == row);
        let index = this.tableData.findIndex((e) => e.field == row);
        if (field?.inputType) {
          this.tableData[index]["inputType"] = field.inputType;
        }
        if (field?.is_relational_field) {
          this.tableData[index]["is_relational_field"] =
            field.is_relational_field;
        }
        if (
          field?.is_nested_relational_field ||
          field?.is_nested_relational_field == "true"
        ) {
          this.$set(this.tableData[index], "is_nested_relational_field", true);
          this.$set(
            this.tableData[index],
            "nested_entity_info",
            field.nested_entity_info
          );
          // console.log("the nested relational",field.is_nested_relational_field, field.nested_entity_info, this.tableData[index],index)
        }
        if (
          index > -1 &&
          (!this.tableData[index].custom_message ||
            (typeof this.tableData[index].custom_message == "object" &&
              Object.keys(this.tableData[index].custom_message).length == 0))
        ) {
          let entity = (this.getAllEntities.data || []).find(
            (e) => e._id == row.split("#")[0]
          );
          if (entity?.primaryFields && entity?.primaryFields[0]) {
            let temp = (this.allTemplates || []).find(
              (e) => e._id == entity?.primaryFields[0].template_id
            );
            this.tableData[index]["custom_message"] = {
              type: "doc",
              content: [
                {
                  type: "paragraph",
                  content: [
                    {
                      type: "mention",
                      attrs: {
                        id: temp?.slug + "#" + entity?.primaryFields[0].key,
                        label: null,
                      },
                    },
                    {
                      type: "text",
                      text: " " + row.split("#")[2],
                    },
                  ],
                },
              ],
            };
          }
        }

        this.fetchEntityFilters(row.split("#")[0]);
      }
    },
    async fetchEntityFilters(entityId) {
      await this.$store.dispatch("filters/fetchAllEntityFilters", {
        entityId: entityId,
      });
      if (this.getAllEntityFilters) {
        this.allEntityFilters.push({
          entity_id: entityId,
          filters: this.getAllEntityFilters,
        });
      }
    },
    insertCalendarFields() {
      if (this.tableData.length > 0) {
        this.calenderDateField = [...this.tableData];
        this.tableData = [];
        this.centerDialogVisible = false;
        this.insertComponent();
      } else {
        // console.log("add");
      }
    },
    deleteRow(index, rows) {
      rows.splice(index, 1);
    },
    addNewRow() {
      let field = {
        field: "",
        custom_message: {},
        show_every_year: false,
        filter_id: "",
        is_relational_field: false,
        is_nested_relational_field: false,
        nested_entity_info: "",
        inputType: "DATE",
        color: "#f754b9",
        applyBackground: false,
      };
      this.refresh = true;
      this.tableData.push(field);
      setTimeout(() => {
        this.refresh = false;
      }, 500);
    },
    displayPopup() {
      if (this.calenderDateField && this.calenderDateField.length) {
        this.tableData = [...this.calenderDateField];
      }
      // this.addNewRow();
      this.centerDialogVisible = true;
    },
    displayLoginsPopup() {
      this.centerLoginsDialogVisible = true;
    },
    async getLoginEntityDateFields() {
      if (this.selectedLoginEntity) {
        this.allEntityFields = await this.fetchEntityDetails(
          this.selectedLoginEntity,
          true,
          false,
          true
        );
        this.allDateTimeFields = this.allEntityFields.filter(
          (e) => e.input_type == "TIME" || e.input_type == "DATE"
        );
        this.entityField = this.allEntityFields.filter(
          (e) => e.input_type == "ENTITY"
        );
        this.textField = this.allEntityFields.filter(
          (e) => e.input_type == "SINGLE_LINE_TEXT"
        );
      } else if (this.isEntityDashboard && this.selectedLoginEntityRelationalData) {
        this.allEntityFields = await this.fetchEntityDetails(
          this.selectedLoginEntityRelationalData,
          true,
          false,
          true
        );
        this.allDateTimeFields = this.allEntityFields.filter(
          (e) => e.input_type == "TIME" || e.input_type == "DATE"
        );
        this.entityField = this.allEntityFields.filter(
          (e) => e.input_type == "ENTITY"
        );
        this.textField = this.allEntityFields.filter(
          (e) => e.input_type == "SINGLE_LINE_TEXT"
        );
      }
    },
    saveLoginsData() {
      this.loginsDataDetails = [...this.info];
      this.info = [];
      this.centerLoginsDialogVisible = false;
    },
    getEntityDateFields() {
      this.getTemplateDateFields = [];
      if (this.selectedCalendarEntities.length) {
        this.selectedCalendarEntities.forEach((entity_id) => {
          const entity = (this.getAllEntities.data || []).find(
            (e) => e._id == entity_id
          );
          const templateIds = (entity.templates || []).flatMap(
            (e) => e.template_id
          );
          const entityTemplates = this.allTemplates.filter(
            (e) => templateIds.indexOf(e._id) !== -1
          );
          entityTemplates.forEach((e) => {
            let data = JSON.parse(JSON.stringify(e));
            this.getTemplateDateFields = [
              ...this.getTemplateDateFields,
              ...this.getTemplateFieldsByType(
                data,
                ["DATE", "DATE_TIME", "DATE_TIME_RANGE", "DATE_RANGE"],
                entity
              ),
            ];
          });
        });
      }
      if (this.selectedCalendarRelationEntities.length) {
        this.selectedCalendarRelationEntities.forEach((entity_id) => {
          const entity = (this.getAllEntities.data || []).find(
            (e) => e._id == entity_id
          );
          const templateIds = (entity.templates || []).flatMap(
            (e) => e.template_id
          );
          const entityTemplates = this.allTemplates.filter(
            (e) => templateIds.indexOf(e._id) !== -1
          );
          entityTemplates.forEach((e) => {
            let data = JSON.parse(JSON.stringify(e));
            let fields = this.getTemplateFieldsByType(
              data,
              ["DATE", "DATE_TIME", "DATE_TIME_RANGE", "DATE_RANGE"],
              entity
            );
            fields = fields.map((el) => {
              el.is_relational_field = true;
              return el;
            });
            this.getTemplateDateFields = [
              ...this.getTemplateDateFields,
              ...fields,
            ];
          });
        });
      }
      if (this.selectedCalendarNestedRelationEntities.length) {
        // console.log("this.selectedCalendarNestedRelationEntities", this.selectedCalendarNestedRelationEntities);
        this.selectedCalendarNestedRelationEntities.forEach((entityPair) => {
          let entity_id = entityPair.split("#")[1];
          const entity = (this.getAllEntities.data || []).find(
            (e) => e._id == entity_id
          );
          const templateIds = (entity.templates || []).flatMap(
            (e) => e.template_id
          );
          const entityTemplates = this.allTemplates.filter(
            (e) => templateIds.indexOf(e._id) !== -1
          );
          entityTemplates.forEach((e) => {
            let data = JSON.parse(JSON.stringify(e));
            let fields = this.getTemplateFieldsByType(
              data,
              ["DATE", "DATE_TIME", "DATE_TIME_RANGE", "DATE_RANGE"],
              entity
            );
            fields = fields.map((el) => {
              el.is_nested_relational_field = true;
              el.nested_entity_info = entityPair;
              return el;
            });
            this.getTemplateDateFields = [
              ...this.getTemplateDateFields,
              ...fields,
            ];
          });
        });
      }
    },
    switchPositions(evt) {
      if (
        evt &&
        typeof evt.oldIndex == "number" &&
        typeof evt.newIndex == "number" &&
        this.customDashboardData.allTableComponents[evt.oldIndex]
      ) {
        let data = JSON.parse(
          JSON.stringify(
            this.customDashboardData.allTableComponents[evt.oldIndex]
          )
        );
        let data1 = JSON.parse(
          JSON.stringify(
            this.customDashboardData.allTableComponents[evt.newIndex]
          )
        );
        data.order = evt.newIndex;
        data1.order = evt.oldIndex;
        this.customDashboardData.allTableComponents.splice(
          evt.oldIndex,
          1,
          data1
        );
        this.customDashboardData.allTableComponents.splice(
          evt.newIndex,
          1,
          data
        );
      }
    },

    getRandomKey() {
      return (Math.random() + 1).toString(36).substring(7);
    },
    async fetchFieldOptionsForChart(field) {
      this.pieChartFieldOptions = [];
      await this.fetchFieldOptions(field);
    },
    tableUpdate(data) {
      this.customDashboardData.allTableComponents[data.index] = {
        ...data.data,
      };
    },
    async saveDashboard() {
      if (this.getIsMobile) {
        this.$message.warning("dashboard edit is not allowed in small screens");
        this.$router.push({ path: "/custom/dashboard-list" });
      }
      this.loading = true;
      // bus.$emit("fetch-all-stats", {});
      let params = {
        name: this.customDashboardData.name,
        components: [
          ...this.customDashboardData.allStatComponents,
          ...this.customDashboardData.allTableComponents,
          ...this.customDashboardData.allCustomFilters,
          ...this.customDashboardData.allMulCustomFilters,
          ...this.customDashboardData.allIntegrationFilters,
        ],
        include_global_date_filter:
          this.customDashboardData.includeGlobalDateFilter,
        date_value: this.customDashboardData.date_value,
        Custom_date_value: this.customDashboardData.Custom_date_value,
        allow_user_change_order: this.customDashboardData.allowUserChangeOrder,
      };
      if (this.$route.params.entityId) {
        params = { ...params, ...{ entity_id: this.$route.params.entityId } };
      }
      let id = this.$route.params.dashboardId || this.$route.query.dashboardId;

      if (id) {
        await this.$store.dispatch("customDashboard/updateDashboard", {
          id: id,
          params: params,
        });
        if (this.getUpdateDashboardStatus) {
          if (this.getUpdateDashboardData?.data && !this.isEntityDashboard) {
            await updateCustomDashboardData(
              id,
              this.getUpdateDashboardData.data
            );
          }
          this.$notify({
            title: "Success",
            message: "Dashboard updated successfully",
            type: "success",
          });
          this.goBack();
        } else {
          let msg =
            this.getUpdateDashboardError && this.getUpdateDashboardError.message
              ? this.getUpdateDashboardError.message
              : "Error while editing dashboard";
          this.$notify({
            title: "Error",
            message: msg,
            type: "error",
          });
        }
      } else {
        await this.$store.dispatch(
          "customDashboard/fetchCreateNewDashboard",
          params
        );
        if (
          this.getCreateNewDashboard &&
          this.getCreateNewDashboard._id &&
          !this.isEntityDashboard
        ) {
          await addSingleCustomDashboardToLocal(this.getCreateNewDashboard);
          this.$notify({
            title: "Success",
            message: "Dashboard updated successfully",
            type: "success",
          });
          // this.$router.push({
          //   path: "/entity",
          // });
          this.goBack();
        } else if (
          this.isEntityDashboard &&
          this.getCreateNewDashboard &&
          this.getCreateNewDashboard._id
        ) {
          this.$notify({
            title: "Success",
            message: "Dashboard updated successfully",
            type: "success",
          });
          this.$router.push({
            path: `/ap-user/custom-dashboards/${this.$route.params.entityId}`,
            query: { name: this.$route.query.name },
          });
        } else {
          let msg =
            this.getCreateNewDashboardError &&
            this.getCreateNewDashboardError.message
              ? this.getCreateNewDashboardError.message
              : "Error while saving data";
          this.$notify({
            title: "Error",
            message: msg,
            type: "error",
          });
        }
      }
      this.loading = false;
    },
    expendTable(index) {
      if (this.expendedIndexs.indexOf(index) == -1) {
        this.customDashboardData.allTableComponents[index]["is_expand"] = true;
        this.expendedIndexs.push(index);
      } else {
        this.expendedIndexs.splice(this.expendedIndexs.indexOf(index), 1);
        this.customDashboardData.allTableComponents[index]["is_expand"] = false;
      }
    },
    deleteComponent(index) {
      this.refreshComponents = true;
      this.customDashboardData.allTableComponents.splice(index, 1);
      setTimeout(() => {
        this.refreshComponents = false;
      }, 500);
    },
    insertComponent() {
      if (this.selectedComponentType == "CHART") {
        this.insertChartComponent();
        this.resetFormData();
      } else if (this.selectedComponentType == "CUSTOM_FILTER") {
        this.insertFilterComponent();
      } else if (this.selectedComponentType == "CALENDAR") {
        this.insertCalendarComponent();
      } else if (this.selectedComponentType == "CARD") {
        this.insertCardComponent();
      } else if (this.selectedComponentType == "LOGINS") {
        this.insertLoginsComponent();
      } else if (this.selectedComponentType == "ENTITY_VIEW") {
        this.insertEntityView();
      } else if (this.selectedComponentType == "SCHEDULING") {
        this.insertScheduler();
      } else if (this.selectedComponentType == "GAUGE_CHART") {
        this.insertGaugeChart();
      } else if (this.selectedComponentType == "NOTIFICATIONS") {
        this.insertNotifications();
      } else if (this.selectedComponentType == "MULTIPLE_CUSTOM_FILTER") {
        this.insertMulCustomFilter();
      }
      // else if (this.selectedComponentType == "GANTT_CHART"){
      //   this.insertGanttChart();
      // }
      else {
        this.insertTableComponent();
        this.resetFormData();
      }
      this.getComponentStatus();
    },
    insertNotifications() {
      this.customDashboardData.allTableComponents.push({
        component_name: this.componentName,
        notifications: this.notifications,
        type: "NOTIFICATIONS",
        feature: "NOTIFICATIONS",
        keyValue: this.getRandomKey(),
        order: this.customDashboardData.allTableComponents.length,
        isExpandTable: false,
      });
      this.resetFormData();
    },
    insertCalendarComponent() {
      this.customDashboardData.allTableComponents.push({
        component_name: this.calendarName,
        entity_id: this.selectedEntityData,
        filter_id: "",
        type: "CALENDAR",
        feature: "ENTITY",
        icon: "dashboard.svg",
        keyValue: this.getRandomKey(),
        order: this.customDashboardData.allTableComponents.length,
        selectedCalendarEntities: [
          ...this.selectedCalendarEntities,
          ...this.selectedCalendarRelationEntities,
          ...this.selectedCalendarNestedRelationEntities,
        ],
        calenderDateField: this.calenderDateField,
        allowOtherShowData: this.allowOtherShowData,
        is_relational_data:
          this.selectedCalendarRelationEntities &&
          this.selectedCalendarRelationEntities.length
            ? true
            : false,
        is_nested_relational_data:
          this.selectedCalendarNestedRelationEntities &&
          this.selectedCalendarNestedRelationEntities.length
            ? true
            : false,
      });
      this.selectedCalendarNestedRelationEntities = [];
      this.selectedCalendarRelationEntities = [];
      this.selectedCalendarEntities = [];
      this.calenderDateField = [];
    },
    insertScheduler() {
      this.customDashboardData.allTableComponents.push({
        component_name: this.schedulerComponentName,
        resource_entity: this.resourceEntity,
        scheduler_entity_date_field: this.schedulerEntityDateField,
        job_entity_time_field: this.jobEntityTimeField,
        resource_entity_time_field: this.resourceEntityTimeField,
        scheduler_entity_sub_groups: this.schedulerEntitySubGroups,
        scheduler_entity_sub_groups_filters:
          this.schedulerEntitySubGroupsFilters,
        job_entity: this.jobEntity,
        scheduler_entity: this.schedulerEntity,
        icon: "dashboard.svg",
        type: "SCHEDULING",
        feature: "SCHEDULING",
        keyValue: this.getRandomKey(),
        order: this.customDashboardData.allTableComponents.length,
        isExpandTable: true,
        applyRelationship: this.applyRelationship,
      });
    },
    resetInsertScheduler() {
      this.schedulerComponentName = "";
      this.resourceEntity = "";
      this.schedulerEntityDateField = "";
      this.jobEntityTimeField = "";
      this.resourceEntityTimeField = "";
      this.jobEntity = "";
      this.schedulerEntity = "";
    },
    insertEntityView() {
      let selectedView = this.getAllEntityViews.data.find(
        (view) => view._id == this.selectedEntityView
      );
      this.customDashboardData.allTableComponents.push({
        component_name: selectedView.name,
        entity_view_id: this.selectedEntityView,
        icon: "dashboard.svg",
        type: "ENTITY_VIEW",
        feature: "ENTITY_VIEW",
        keyValue: this.getRandomKey(),
        order: this.customDashboardData.allTableComponents.length,
        isExpandTable: false,
        is_relational_data: this.isRelationalData,
      });
      this.selectedEntityView = "";
    },
    insertGaugeChart() {
      this.customDashboardData.allTableComponents.push({
        component_name: this.componentName,
        numerator: this.gaugeChartNumerator,
        denominator: this.gaugeChartDenominator,
        icon: "dashboard",
        type: "GAUGE_CHART",
        feature: "GAUGE_CHART",
        keyValue: this.getRandomKey(),
        order: this.customDashboardData.allTableComponents.length,
        isExpandTable: false,
        filters: this.gaugeFilters,
        customization: this.gaugeChartCustomization,
      });
      this.gaugeChartNumerator = {
        display_type: "count",
      };
      this.gaugeChartDenominator = {
        display_type: "count",
      };
      this.gaugeFilters = [];
      this.componentName = "";
    },
    // insertGanttChart() {
    //   this.customDashboardData.allTableComponents.push({
    //     component_name: "Gantt Chart",
    //     icon: "dashboard.svg",
    //     type: "GANTT_CHART",
    //     feature: "ENTITY",
    //     keyValue: this.getRandomKey(),
    //     isExpandTable: false,
    //     order: this.customDashboardData.allTableComponents.length,
    //   });

    // },
    insertChartComponent() {
      let entity_id = "";
      this.selectedEntityRelationalData;
      if (this.isEntityDashboard && this.selectedEntityRelationalData) {
        entity_id = this.selectedEntityRelationalData;
      } else {
        entity_id = this.selectedEntityData;
      }
      this.customDashboardData.allTableComponents.push({
        component_name: this.chartComponentName,
        entity_id: entity_id,
        filter_id: "",
        type: "CHART",
        chart_type: this.chartType,
        combinedData: this.combinedData,
        feature: "ENTITY",
        icon: "dashboard.svg",
        isExpandTable: false,
        pie_chart_field: this.pieChartField,
        pie_chart_field_options: this.pieChartFieldOptions,
        pie_chart_field_group_by: this.pieChartFieldGroupBy,
        pie_chart_field_group_by_selected_options:
          this.pieChartFieldGroupBySelectedOptions,
        date_filter_field: this.date_filter_field,
        include_chart_total: this.includeChartTotal,
        sum_fields: this.sum_fields,
        keyValue: this.getRandomKey(),
        order: this.customDashboardData.allTableComponents.length,
        is_relational_data:
          this.isEntityDashboard && this.selectedEntityRelationalData
            ? true
            : false,
        chart_selected_filter: this.chartSelectedFilter,
        BarColor: this.BarColor,
        isStacked: this.isStacked,
        startFrom: this.startFrom,
        showLegend: true,
        showIcon: true,
      });
      this.resetPieModel();
    },
    async insertLoginsComponent() {
      let entity_id = "";
      if (this.isEntityDashboard && this.selectedLoginEntityRelationalData) {
        entity_id = this.selectedLoginEntityRelationalData;
      } else {
        entity_id = this.selectedLoginEntity;
      }
      this.customDashboardData.allTableComponents.push({
        component_name: "LOGINS",
        type: "LOGINS",
        entity_id: entity_id,
        filter_id: "",
        feature: "ENTITY",
        icon: "dashboard.svg",
        keyValue: this.getRandomKey(),
        isExpandTable: false,
        order: this.customDashboardData.allTableComponents.length,
        selectedLoginEntity: this.selectedLoginEntity,
        start_button: this.start_button,
        end_button: this.end_button,
        loginsDataDetails: this.loginsDataDetails,
        selectedEntityRelationalData: this.selectedLoginEntityRelationalData,
        is_relational_data:
          this.isEntityDashboard && this.selectedLoginEntityRelationalData
            ? true
            : false,
        Days: this.Days,
        auto_logout: this.auto_logout,
        user_field: this.user_field,
        companyuser_field: this.companyuser_field,
      });
      this.selectedLoginEntity = "";
      this.start_button = "";
      this.end_button = "";
      this.loginsDataDetails = [];
      this.selectedLoginEntityRelationalData = "";
      if (this.auto_logout) {
        let params = {
          entityId: entity_id,
        };
        await this.$store.dispatch("entities/callCronForAutoLogout", params);
      }
    },
    resetFormData() {
      this.selectedEntityData = "";
      this.sum_fields = [];
      this.pieChartField = "";
      this.pieChartFieldOptions = [];
      this.pieChartFieldGroupBy = "";
      this.pieChartFieldGroupBySelectedOptions = [];
      this.selectedEntityRelationalData = "";
      this.notifications = [];
      this.componentName = "";
    },
    resetPieModel() {
      (this.BarColor = ""), (this.chartType = "");
      this.pieChartField = "";
      this.pieChartFieldOptions = [];
      this.chartSelectedFilter = "";
      this.chartComponentName = "";
      this.combinedData = [];
      this.isStacked = false;
      this.startFrom = false;
      (this.date_filter_field = ""),
        (this.rows = [{ sum_field: null, chart_type: null }]);
    },
    insertFilterComponent() {
      // const fields = this.getTemplateFields.filter(
      //   e => this.customFilterField.indexOf(e.key) !== -1
      // );
      let existedCustomFilters =
        this.customDashboardData.allCustomFilters.filter(
          (e) =>
            e.entity_id == this.selectedEntityData ||
            e.entity_id === this.selectedEntityRelationalData
        ) || [];
      if (existedCustomFilters && existedCustomFilters.length) {
        existedCustomFilters.forEach((e) => {
          if (this.customFilterField.indexOf(e.filter_field) === -1) {
            let removeIndex =
              this.customDashboardData.allCustomFilters.findIndex(
                (el) =>
                  (el.entity_id == this.selectedEntityData ||
                    el.entity_id == this.selectedEntityRelationalData) &&
                  el.filter_field == e.filter_field
              );
            this.removeFilterComponent(removeIndex);
          }
        });
      }
      this.customFilterField.forEach((e) => {
        let existedComponent = this.customDashboardData.allCustomFilters.find(
          (j) => {
            if (j.entity_id == this.selectedEntityData && j.filter_field == e) {
              return j;
            }
          }
        );
        if (!existedComponent) {
          let fieldType = this.getTemplateFields.find(
            (field) => field.key === e
          );
          this.customDashboardData.allCustomFilters.push({
            component_name: "",
            entity_id:
              this.isEntityDashboard && this.selectedEntityRelationalData
                ? this.selectedEntityRelationalData
                : this.selectedEntityData,
            filter_id: "",
            type: "CUSTOM_FILTER",
            feature: "ENTITY",
            icon: "dashboard.svg",
            filter_field: e,
            filter_field_type: fieldType.inputType,
            filter_field_entity_id: fieldType.entity_id,
            selected_filter: "",
            keyValue: this.getRandomKey(),
            is_relational_data:
              this.isEntityDashboard &&
              this.getRelationshipEntities.filter(
                (e) => e._id == fieldType.entity_id
              ).length
                ? true
                : false,
          });
        }
      });
      bus.$emit("apply-custom-filter", {
        reset: true,
      });
      // this.resetFilterModal();
    },
    insertMulCustomFilter() {
      const filterFieldEntityId = this.multipleCustomFilterField.split("-")[0];
      let existingFilters = this.customDashboardData.allMulCustomFilters.filter(
        (filter) => filter.filter_field_entity_id === filterFieldEntityId
      );

      if (existingFilters.length) {
        existingFilters.forEach((filter) => {
          filter.entities.forEach((entity, index) => {
            if (
              this.selectedMulCustomFilterEntity.indexOf(entity.entity_id) ===
              -1
            ) {
              filter.entities.splice(index, 1);
            }
          });

          if (
            filter.entities.length === 0 ||
            this.selectedMulCustomFilterEntity.length === 0
          ) {
            const removeIndex =
              this.customDashboardData.allMulCustomFilters.findIndex(
                (f) => f.filter_field_entity_id === filterFieldEntityId
              );

            this.customDashboardData.allMulCustomFilters.splice(removeIndex, 1);
          }
        });
      }

      let existingComponent = this.customDashboardData.allMulCustomFilters.find(
        (filter) => filter.filter_field_entity_id === filterFieldEntityId
      );
      if (!existingComponent) {
        const entityIds = this.selectedMulCustomFilterEntity;
        const filterFields = this.orderedMatchingFieldKeys;
        const combinedArray = [];
        for (let i = 0; i < entityIds.length; i++) {
          combinedArray.push({
            entity_id: entityIds[i],
            filter_field: filterFields[i],
          });
        }
        if (combinedArray.length > 0) {
          this.customDashboardData.allMulCustomFilters.push({
            component_name: "",
            entities: combinedArray,
            filter_id: "",
            type: "MULTIPLE_CUSTOM_FILTER",
            feature: "ENTITY",
            icon: "dashboard.svg",
            filter_field_type: "",
            filter_field_entity_id: filterFieldEntityId,
            selected_filter: "",
            keyValue: this.getRandomKey(),
            multiple_filter_dependency: this.multipleFilterDependency,
          });
        }
      } else {
        this.selectedMulCustomFilterEntity.forEach((entityId) => {
          const alreadyExists = existingComponent.entities.some(
            (entity) => entity.entity_id === entityId
          );
          if (!alreadyExists) {
            existingComponent.entities.push({
              entity_id: entityId,
              multiple_filter_dependency: this.multipleFilterDependency,
              filter_field:
                this.orderedMatchingFieldKeys[
                  this.selectedMulCustomFilterEntity.indexOf(entityId)
                ],
            });
          }
        });
      }
      this.multipleCustomFilterField = "";
      this.selectedMulCustomFilterEntity = [];
      this.matchingFieldKeys = [];
      this.orderedMatchingFieldKeys = [];
      this.multipleFilterDependency = "";
    },
    resetFilterModal() {
      this.customFilterField = [];
    },
    removeFilterComponent(index) {
      this.customDashboardData.allCustomFilters.splice(index, 1);
    },
    insertCardComponent() {
      let existedEntityFilters =
        this.customDashboardData.allTableComponents.filter(
          (e) => e.entity_id == this.selectedEntityData
        ) || [];
      if (existedEntityFilters && existedEntityFilters.length) {
        existedEntityFilters.forEach((e) => {
          if (this.selectedFilter.indexOf(e.filter_id) === -1) {
            let removeIndex =
              this.customDashboardData.allTableComponents.findIndex(
                (el) =>
                  el.entity_id == this.selectedEntityData &&
                  el.filter_id == e.filter_id
              );
            this.deleteComponent(removeIndex);
          }
        });
      }
      if (this.selectedFilter && this.selectedFilter.length) {
        this.selectedFilter.forEach((filter) => {
          let existedComponent =
            this.customDashboardData.allTableComponents.find((j) => {
              if (
                j.entity_id == this.selectedEntityData &&
                j.filter_id == filter
              ) {
                return j;
              }
            });
          if (!existedComponent) {
            let selectedEntity = this.getAllEntitiesData.find(
              (e) => e._id == this.selectedEntityData
            );
            let selectedEntityFilter = this.filters.find(
              (e) => e._id == filter
            );
            let name = "";
            if (selectedEntity && selectedEntity.name) {
              name = selectedEntity.name;
              if (selectedEntityFilter && selectedEntityFilter.filter_name) {
                name = name + " " + selectedEntityFilter.filter_name;
              }
            }
            let components = {
              component_name: name,
              entity_id: this.selectedEntityData,
              filter_id: filter,
              type: "CARD",
              feature: "ENTITY",
              icon: "dashboard.svg",
              keyValue: this.getRandomKey(),
              order: this.customDashboardData.allTableComponents.length,
            };
            this.customDashboardData.allTableComponents.push(components);
          }
        });
      } else {
        let selectedEntity = this.getAllEntitiesData.find(
          (e) => e._id == this.selectedEntityData
        );
        let name = "";
        if (selectedEntity && selectedEntity.name) {
          name = selectedEntity.name;
        }
        let components = {
          component_name: name,
          entity_id: this.selectedEntityData,
          type: "CARD",
          feature: "ENTITY",
          icon: "dashboard.svg",
          keyValue: this.getRandomKey(),
          self_entity: true,
          order: this.customDashboardData.allTableComponents.length,
        };
        this.customDashboardData.allTableComponents.push(components);
      }
    },
    insertTableComponent() {
      let entity_id = "";
      let is_relational_data =
        this.isEntityDashboard && this.selectedEntityRelationalData
          ? true
          : false;
      this.selectedFilter = this.selectedFilter.filter((e) => e);
      if (this.isEntityDashboard && this.selectedEntityRelationalData) {
        entity_id = this.selectedEntityRelationalData;
        is_relational_data = true;
      } else {
        entity_id = this.selectedEntityData;
      }
      let existedEntityFilters =
        this.customDashboardData.allTableComponents.filter(
          (e) =>
            e.type == "TABLE" &&
            e.entity_id == entity_id &&
            ((!e.is_relational_data && !is_relational_data) ||
              is_relational_data == e.is_relational_data)
        ) || [];
      if (existedEntityFilters && existedEntityFilters.length) {
        existedEntityFilters.forEach((e) => {
          if (
            this.selectedFilter.length &&
            this.selectedFilter.indexOf(e.filter_id) === -1
          ) {
            let removeIndex =
              this.customDashboardData.allTableComponents.findIndex(
                (el) =>
                  el.type == "TABLE" &&
                  el.filter_id &&
                  el.entity_id == entity_id &&
                  el.filter_id == e.filter_id &&
                  ((!el.is_relational_data && !is_relational_data) ||
                    is_relational_data == el.is_relational_data)
              );
            if (removeIndex > -1) {
              this.deleteComponent(removeIndex);
            }
          }
        });
      }
      if (this.selectedFilter && this.selectedFilter.length) {
        this.selectedFilter.forEach((filter) => {
          let existedComponent =
            this.customDashboardData.allTableComponents.find((j) => {
              if (
                !j.table_field_group_by &&
                j.type == "TABLE" &&
                j.entity_id == entity_id &&
                j.filter_id == filter &&
                ((!j.is_relational_data && !is_relational_data) ||
                  is_relational_data == j.is_relational_data)
              ) {
                return j;
              }
            });
          if (!existedComponent || this.tableFieldGroupBy) {
            let selectedEntity = this.getAllEntitiesData.find(
              (e) => e._id == this.selectedEntityData
            );
            let selectedEntityFilter = this.filters.find(
              (e) => e._id == filter
            );
            let name = "";
            if (selectedEntity && selectedEntity.name) {
              name = selectedEntity.name;
              if (selectedEntityFilter && selectedEntityFilter.filter_name) {
                name = name + " " + selectedEntityFilter.filter_name;
              }
            }
            let components = {
              component_name: name,
              entity_id: entity_id,
              filter_id: filter,
              type: "TABLE",
              feature: "ENTITY",
              icon: "dashboard.svg",
              keyValue: this.getRandomKey(),
              isExpandTable:
                this.customDashboardData.allTableComponents.isExpandTable,
              order: this.customDashboardData.allTableComponents.length,
              is_relational_data:
                this.isEntityDashboard && this.selectedEntityRelationalData
                  ? true
                  : false,
              table_field_group_by: this.tableFieldGroupBy,
              table_field_sub_group_by: this.tableFieldSubGroupBy,
              table_field_group_by_fields: this.tableFieldGroupByFields,
              table_field_group_by_selected_options:
                this.tableFieldGroupBySelectedOptions,
              send_notification: false,
              ShowExport: this.ShowExport,
            };
            this.customDashboardData.allTableComponents.push(components);
          }
        });
      } else {
        let selectedEntity = this.getAllEntitiesData.find(
          (e) => e._id == entity_id
        );
        let name = "";
        if (selectedEntity && selectedEntity.name && is_relational_data) {
          name = "My " + selectedEntity.name;
        }
        let components = {
          component_name: name,
          entity_id: entity_id,
          type: "TABLE",
          feature: "ENTITY",
          icon: "dashboard.svg",
          keyValue: this.getRandomKey(),
          self_entity: true,
          isExpandTable: false,
          order: this.customDashboardData.allTableComponents.length,
          is_relational_data: is_relational_data,
          table_field_group_by: this.tableFieldGroupBy,
          table_field_sub_group_by: this.tableFieldSubGroupBy,
          table_field_group_by_fields: this.tableFieldGroupByFields,
          table_field_group_by_selected_options:
            this.tableFieldGroupBySelectedOptions,
          ShowExport: this.ShowExport,
          send_notification: false,
        };
        this.customDashboardData.allTableComponents.push(components);
      }
      this.resetTableComponentData();
    },
    resetTableComponentData() {
      this.tableFieldGroupBy = "";
      this.tableFieldSubGroupBy = "";
      this.tableFieldGroupBySelectedOptions = [];
      this.tableFieldGroupByFields = [
        {
          key: "count",
          name: "Count",
          type: "count",
          sub_group: false,
        },
      ];
      this.selectedEntityData = [];
      this.ShowExport = false;
    },
    getComponentInfo() {
      if (
        this.selectedComponentType == "CHART" ||
        this.selectedComponentType == "CALENDAR"
      ) {
        this.fetchTemplateFields(true);
      } else if (
        this.selectedComponentType == "CUSTOM_FILTER" ||
        this.selectedComponentType == "TABLE"
      ) {
        this.fetchTemplateFields(false);
      } else if (this.selectedComponentType == "MULTIPLE_CUSTOM_FILTER") {
        this.handleFieldChange();
      } else {
        this.fetchAllEntityFilters(this.selectedEntityData);
      }
    },
    fetchTemplateFields(isChart) {
      if (!this.selectedEntityData && !this.selectedEntityRelationalData) {
        return;
      }
      let id = this.selectedEntityData
        ? this.selectedEntityData
        : this.selectedEntityRelationalData;
      this.fetchAllEntityFilters(id);
      let entity = this.getAllEntitiesData.find((e) => e._id == id);
      if (!entity) {
        entity = this.getRelationshipEntities.find((e) => e._id == id);
      }
      if (isChart) {
        this.pieChartField = "";
      }
      const templateIds = (entity.templates || [])
        .filter((e) => !e.is_repeatable)
        .flatMap((e) => e.template_id);
      const entityTemplates = this.allTemplates.filter(
        (e) => templateIds.indexOf(e._id) !== -1
      );
      this.getTemplateFields = [];
      this.getTotalFields = [];
      this.getAllDateFields = [];
      entityTemplates.forEach((e) => {
        let data = JSON.parse(JSON.stringify(e));
        let data1 = JSON.parse(JSON.stringify(e));
        this.getAllDateFields = [
          ...this.getAllDateFields,
          ...this.getTemplateFieldsByType(data1, [
            "DATE",
            "DATE_TIME",
            "DATE_RANGE",
          ]),
        ];
        this.getTemplateFields = [
          ...this.getTemplateFields,
          ...this.getTemplateFieldsByType(
            data,
            isChart
              ? [
                  "SELECT",
                  "YES_OR_NO",
                  "MULTI_SELECT",
                  "ENTITY",
                  "DATE",
                  "WEEKDAYS",
                  // "DATE_TIME",
                ]
              : ["SELECT", "ENTITY", "MULTI_SELECT", "DATE"]
          ),
        ];
        this.getTotalFields = [
          ...this.getTotalFields,
          ...this.getTemplateFieldsByType(data, [
            "NUMBER",
            "CURRENCY",
            "FORMULA",
            "AGGREGATE_FUNCTION",
          ]),
        ];
      });
      this.customFilterField = this.customDashboardData.allCustomFilters
        .filter(
          (e) =>
            e.entity_id === this.selectedEntityData ||
            e.entity_id === this.selectedEntityRelationalData
        )
        .flatMap((e) => e.filter_field);
    },
    async fetchAllEntityFilters(entityId) {
      if (!entityId) {
        return;
      }
      this.chartFilters = [];
      this.loading = true;
      await this.$store.dispatch("filters/fetchAllEntityFilters", {
        entityId: entityId,
      });
      if (this.addStatsModal) {
        this.statEntityFilters = [];
        if (this.getAllEntityFilters) {
          this.statEntityFilters = [...this.getAllEntityFilters];
        }
      } else {
        this.filters = [];
        this.selectedFilter = [];
        if (this.getAllEntityFilters) {
          this.filters = this.getAllEntityFilters;
          this.entityFilterObject[entityId] = this.getAllEntityFilters;
          let entity_id = "";
          let is_relational_data =
            this.isEntityDashboard && this.selectedEntityRelationalData
              ? true
              : false;
          this.selectedFilter = this.selectedFilter.filter((e) => e);
          if (this.isEntityDashboard && this.selectedEntityRelationalData) {
            entity_id = this.selectedEntityRelationalData;
          } else {
            entity_id = this.selectedEntityData;
          }
          let existedFilters =
            this.customDashboardData.allTableComponents.filter(
              (u) =>
                u.entity_id == entity_id &&
                u.is_relational_data == is_relational_data
            );
          this.selectedFilter =
            existedFilters && existedFilters.length
              ? existedFilters.map((e) => e.filter_id)
              : [];
        }
      }
      this.chartFilters = this.getAllEntityFilters;
      this.loading = false;
    },
    async getNecessaryInfo() {
      this.loading = true;
      this.loadingText = "Fetching dashboard data...";
      // const query = { get_all: true };
      if (this.isEntityDashboard) {
        if (this.$route.query.dashboardId) {
          await this.$store.dispatch(
            "customDashboard/getDashboardById",
            this.$route.query.dashboardId
          );
        } else {
          this.$store.commit("customDashboard/setSingleDashboardData", null, {
            root: true,
          });
        }
        await Promise.all([
          this.$store.dispatch(
            "applicationUsersPermission/fetchPermissionsEntityById",
            { id: this.$route.params.entityId }
          ),
          this.$store.dispatch("entityRelationships/fetchEntityRelationships", {
            entity_id: this.$route.params.entityId,
          }),
        ]);
      } else {
        await this.$store.dispatch(
          "customDashboard/getDashboardById",
          this.$route.params.dashboardId
        );
      }
      // let params = { get_all: true, include_standard: true, populate_data: true };
      let [
        entities,
        templates,
        globalVariables,
        entityViews,
        integrations,
        predefinedIntegrations,
      ] = await Promise.all([
        this.fetchAllEntities(false),
        this.fetchAllTemplates(true),
        fetchGlobalVariables({
          get_all: true,
        }),
        fetchEntityViews({ get_all: true }),
        postAPICall("GET", `integrations`, { get_all: true }),
        postAPICall("GET", "integrations/service/get-all-applications"),
        // this.$store.dispatch("entities/fetchEntities", {
        //   get_all: true,
        // }),
        // this.$store.dispatch("companyTemplates/getAllCompanyTemplates", {
        //   get_all: true,
        //   include_standard: true,
        //   populate_data: true,
        // }),
        // this.$store.dispatch("globalVariables/fetchGlobalVariables", query),
        // this.$store.dispatch("entityViews/getAllEntityViews", {get_all : true})
      ]);
      if (templates) {
        this.allTemplates = templates;
      }
      if (entities) {
        this.getAllEntities = {
          data: entities,
        };
      }
      if (templates?.data) {
        this.allTemplates = templates.data;
      }
      if (entities?.data) {
        this.getAllEntities = { ...entities };
      }
      if (globalVariables && globalVariables.data) {
        this.globalVariablesData = [...globalVariables.data];
      }
      if (entityViews?.data) {
        this.getAllEntityViews = { ...entityViews };
      }
      if (integrations?.data) {
        this.integrations = integrations.data;
      }
      if (predefinedIntegrations?.data) {
        this.predefinedIntegrations = predefinedIntegrations.data;
      }
      // let response = await axios.get("template/list", {
      //   params,
      // });
      // this.getAllCompanyTemplatesData = { ...response.data };
      if (this.getEntityPermission?._id) {
        this.setPermittedEntities();
      }
      if (this.getEntityRelationships && this.$route.params.entityId) {
        this.setRelationshipPermissions(this.$route.params.entityId);
      }
      if (this.getSingleDashboardData) {
        this.setData(this.getSingleDashboardData);
        if (!this.isEntityDashboard) {
          updateCustomDashboardData(
            this.getSingleDashboardData._id,
            this.getSingleDashboardData
          );
        }
      } else {
        if (this.isEntityDashboard && this.getAllEntities) {
          let selectedEntity = (this.getAllEntities.data || []).find(
            (e) => e._id == this.$route.params.entityId
          );
          if (selectedEntity?.name) {
            this.customDashboardData.name = selectedEntity.name + " Dashboard";
          }
        }
      }
      this.loading = false;
    },
    setRelationshipPermissions(id) {
      this.getEntityRelationships.forEach((el) => {
        if (el?.child_entity_id !== id) {
          this.relationshipEntities.push(el.child_entity_id);
        } else if (this.selectedEntityId !== id) {
          this.relationshipEntities.push(el.parent_entity_id);
        }
      });
    },
    setPermittedEntities() {
      this.permittedEntities = [];
      this.permittedRelatedEntities = [];
      this.permittedNestedRelatedEntities = [];
      if (this.getEntityPermission?.other_entities) {
        Object.keys(this.getEntityPermission?.other_entities).forEach((e) => {
          if (
            this.getEntityPermission?.other_entities[e].permissions &&
            this.getEntityPermission?.other_entities[e].permissions.indexOf(
              "VIEW"
            ) != -1
          ) {
            this.permittedEntities.push(e);
          }
        });
      }
      if (this.getEntityPermission?.relational_entities) {
        Object.keys(this.getEntityPermission?.relational_entities).forEach(
          (e) => {
            if (
              this.getEntityPermission?.relational_entities[e].permissions &&
              this.getEntityPermission?.relational_entities[
                e
              ].permissions.indexOf("VIEW") != -1
            ) {
              this.permittedRelatedEntities.push(e);
            }
          }
        );
      }
      if (this.getEntityPermission?.nested_relational_entities) {
        Object.keys(
          this.getEntityPermission?.nested_relational_entities
        ).forEach((e) => {
          if (
            this.getEntityPermission?.nested_relational_entities[e]
              .permissions &&
            this.getEntityPermission?.nested_relational_entities[
              e
            ].permissions.indexOf("VIEW") != -1
          ) {
            this.permittedNestedRelatedEntities.push(e);
          }
        });
      }
    },
    setData(data) {
      this.customDashboardData.name = data.name;
      this.customDashboardData.includeGlobalDateFilter =
        data.include_global_date_filter;
      this.customDashboardData.date_value = data.date_value;
      this.customDashboardData.Custom_date_value = data.Custom_date_value;
      this.customDashboardData.allowUserChangeOrder =
        data.allow_user_change_order;
      this.customDashboardData.allCustomFilters = data.components
        .filter((e) => e.type == "CUSTOM_FILTER")
        .map((e) => {
          e.value = "";
          e.selected_filter = "";
          if (e.selected_date_picker) {
            e.selected_date_picker = "";
          }
          e.keyValue = this.getRandomKey();
          return e;
        });
      this.customDashboardData.allMulCustomFilters = data.components
        .filter((e) => e.type == "MULTIPLE_CUSTOM_FILTER")
        .map((e) => {
          e.value = "";
          e.selected_filter = "";
          if (e.selected_date_picker) {
            e.selected_date_picker = "";
          }
          // e.keyValue = this.getRandomKey();
          return e;
        });
      this.customDashboardData.allIntegrationFilters = data.components.filter(
        (e) => e.type == "INTEGRATION_FILTER"
      );
      this.customDashboardData.allTableComponents = [];
      let allMainComponents = data.components
        .filter((e) =>
          [
            "TABLE",
            "CHART",
            "CALENDAR",
            "LOGINS",
            "ENTITY_VIEW",
            "GAUGE_CHART",
            "NOTIFICATIONS",
            "SCHEDULING",
            // "GANTT_CHART",
          ].includes(e.type)
        )
        .map((e) => {
          e.customFilers = this.customDashboardData.allCustomFilters
            .filter((el) => el.entity_id === e.entity_id && el.selected_filter)
            .map((fl) => {
              fl.field = fl.filter_field;
              fl.value = fl.selected_filter;
              fl.operator = "=case-insensitive";
              fl.query_type = "AND";
              fl.data_type = "STRING";
              return fl;
            });
          e.keyValue = this.getRandomKey();
          return e;
        });
      allMainComponents = allMainComponents.filter((e) => {
        if (e.type == "TABLE" && (!e.entity_id || !e.entity_id.length)) {
          return false;
        }
        return true;
      });
      this.customDashboardData.allTableComponents = allMainComponents.sort(
        (a, b) => (a.order < b.order ? -1 : 1)
      );
      this.customDashboardData.allStatComponents = data.components
        .filter((e) => e.type == "STAT")
        .map((e) => {
          e.customFilers = this.customDashboardData.allCustomFilters
            .filter((el) => el.entity_id === e.entity_id && el.selected_filter)
            .map((fl) => {
              fl.field = fl.filter_field;
              fl.value = fl.selected_filter;
              fl.operator = "=case-insensitive";
              fl.query_type = "AND";
              fl.data_type = "STRING";
              return fl;
            });
          if (!e.keyValue) {
            e.keyValue = this.getRandomKey();
          }
          return e;
        });
      (this.customDashboardData.allTableComponents || []).forEach((el, i) => {
        if (el.type == "CALENDAR" && el.calenderDateField) {
          el.calenderDateField.forEach((field) => {
            this.getEntityFilters(field.field);
          });
        }
        if (el.is_expand) {
          this.expendedIndexs.push(i);
        }
      });
      this.rawData = JSON.parse(
        JSON.stringify({ ...this.customDashboardData })
      );
    },
    searchFilters() {
      if (this.filterSearchString.length > 0) {
        const regex = new RegExp(this.filterSearchString, "i");
        const matchedFilters = (this.getAllEntityFilters || []).filter(
          ({ filter_name }) => filter_name.match(regex)
        );
        this.filters = [...[], ...matchedFilters];
      } else {
        this.filters = [...[], ...(this.getAllEntityFilters || [])];
      }
    },
    getComponentStatus() {
      this.$notify({
        title: "Success",
        message: "Component saved successfully",
        type: "success",
      });
    },
    goBackSave() {
      if (
        JSON.stringify(this.rawData) !==
        JSON.stringify(this.customDashboardData)
      ) {
        this.$router.push({ path: "/custom/dashboard-list" });
      } else {
        if (this.$route.params.entityId) {
          this.$router.push({ path: "/entity" });
          // If there is Entity ID in the route then return to entities list
        } else {
          // if there is no Entity ID then return to custom dashboards list screen
          this.$router.push({ path: "/custom/dashboard-list" });
        }
      }
    },

    goBack() {
      if (this.isEntityDashboard) {
        this.$router.push(
          `/ap-user/custom-dashboards/${this.$route.params.entityId}?name=${this.$route.query.name}`
        );
      } else {
        if (this.checkUrlType() == "private") {
          this.$router.push(
            `/if/${this.getAuthenticatedSSOToken}private/custom/dashboard-list`
          );
        } else {
          this.$router.push({ path: "/custom/dashboard-list" });
        }
      }
    },
    displayChartsPopup() {
      this.centerChartsDialogVisible = true;
    },
    addRow() {
      this.combinedData = this.rows;
      this.rows.push({ sum_field: null, chart_type: null });
    },
    removeRow(index) {
      if (this.rows.length > 1) {
        this.rows.splice(index, 1);
      }
    },
    isOptionDisabled(selectedField, fieldKey, rowIndex) {
      for (let i = 0; i < rowIndex; i++) {
        if (this.rows[i].sum_field === fieldKey) {
          return true;
        }
      }
      return false;
    },
    chartsPopUpClose() {
      this.chartType = "";
      this.centerChartsDialogVisible = false;
    },
    saveChartData() {
      if (this.rows[0].sum_field != null) {
        this.combinedData = this.rows;
      }
      let ex = this.customDashboardData.allTableComponents.filter(
        (e, i) =>
          i !== this.index &&
          e.component_name.toLowerCase() ==
            this.chartComponentName.toLowerCase()
      );
      if (ex && ex.length) {
        this.$notify.error({
          title: "Error",
          message: "Component name already exists",
        });
        return;
      } else {
        this.centerChartsDialogVisible = false;
        this.insertComponent();
      }
    },
    getEntityDateFromFields() {
      let entities = this.getCurrentEntitiesData;
      const matchingEntities = [];
      const templateFields = [];
      entities.forEach((entityObject) => {
        const id = entityObject._id;
        let entity = this.getAllEntitiesData.find((e) => e._id === id);
        if (!entity) {
          entity = this.getRelationshipEntities.find((e) => e._id === id);
        }

        const templateIds = (entity.templates || [])
          .filter((e) => !e.is_repeatable)
          .map((e) => e.template_id);

        const entityTemplates = this.allTemplates.filter((e) =>
          templateIds.includes(e._id)
        );

        entityTemplates.forEach((e) => {
          const data = JSON.parse(JSON.stringify(e));
          const fieldTypes = ["ENTITY"];
          const fields = this.getTemplateFieldsByType(data, fieldTypes);
          const filteredFields = fields.filter(
            (field) => field.inputType === "ENTITY"
          );
          templateFields.push(...filteredFields);
          const matchingFields = filteredFields.filter((field) => {
            if (this.multipleCustomFilterField.length) {
              const isMatching =
                field.entity_id ===
                this.multipleCustomFilterField.split("-")[0];
              if (isMatching) {
                this.matchingFieldKeys.push({ [entityObject._id]: field.key });
              }
              return isMatching;
            }
          });
          if (matchingFields.length > 0) {
            matchingEntities.push(entityObject);
          }
        });
      });

      this.customDashboardData.allMulCustomFilters.forEach((filter) => {
        if (this.multipleCustomFilterField.length) {
          if (
            filter.filter_field_entity_id ===
            this.multipleCustomFilterField.split("-")[0]
          ) {
            filter.entities.forEach((entity) => {
              if (
                !this.selectedMulCustomFilterEntity.includes(entity.entity_id)
              ) {
                this.selectedMulCustomFilterEntity.push(entity.entity_id);
              }
            });
          }
        }
      });
      this.matchingFieldKeys = [...new Set(this.matchingFieldKeys)];
      return matchingEntities;
    },
    handleFieldChange() {
      this.entityOptions = this.getEntityDateFromFields();
      if (this.multipleCustomFilterField) {
        this.buildFilterDependencies();
      }
    },
    async buildFilterDependencies() {
      /* Mixins */
      let entityId = this.multipleCustomFilterField.split("-")[0];
      await this.fetchRelationships(entityId);
      let relationships = this.relationshipData[entityId];
      this.setDependencies(
        entityId,
        relationships,
        this.customDashboardData.allMulCustomFilters
      );
    },
    setDependencies(entityId, relationships, components) {
      this.multipleFilterDependencies = [];
      components.map((component) => {
        let relationship = relationships.find(
          (rl) =>
            component.filter_field_entity_id != entityId &&
            (component.filter_field_entity_id == rl.parent_entity_id ||
              component.filter_field_entity_id == rl.child_entity_id)
        );
        if (relationship) {
          let entity = this.getAllEntitiesData.find(
            (e) => e._id == component.filter_field_entity_id
          );
          if (!entity) {
            entity = this.getRelationshipEntities.find(
              (e) => e._id == component.filter_field_entity_id
            );
          }
          this.multipleFilterDependencies.push({
            label: entity.name,
            key: component.keyValue,
          });
          console.log(
            "this.multipleFilterDependencies",
            this.multipleFilterDependencies
          );
        }
      });
    },
    updateMatchingFieldKeys() {
      const entityToKeyMap = this.matchingFieldKeys.reduce((acc, item) => {
        const [entityId, key] = Object.entries(item)[0];
        acc[entityId] = key;
        return acc;
      }, {});
      this.orderedMatchingFieldKeys = this.selectedMulCustomFilterEntity
        .map((entityId) => entityToKeyMap[entityId])
        .filter((key) => key !== undefined);
    },
    updateDateFilter(value) {
      this.customDashboardData["date_value"] = value;
    },
    updateCustomDateFilter(value) {
      this.customDashboardData["Custom_date_value"] = value;
    },
    DateFilterUpdate() {
      this.dateFilter = true;
    },
    updateDashboardStats(updatedStats) {
      this.customDashboardData.allStatComponents = updatedStats;
    },
  },
};
</script>
<style lang="scss">
.scrollbar-container {
  height: 50vh;
  overflow-y: auto;
}
.calendar-header {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 10px;
  width: 100% !important;
}
.left-side-menu {
  height: 100vh;
  padding: 5px;
  border-right: 1px solid #f2f2f2;
  /* transform: translate3d(0px, 0, 0);
  position: fixed;
  box-sizing: border-box;
  left: 0;
  bottom: 0;
  top: 67.5px;
  z-index: 999;
  background: white;
  
  width: 270px;
  padding-top: 10px;
  margin-top: 50px;
  background-color: aqua;
  color:black;
  border: 2px solid blue;
  width: 270px;
  transform: translate3d(0px, 0, 0);
  position: fixed;
  box-sizing: border-box;
  left: 0;
  bottom: 0;
  top: 100px; */
}
.side-menu {
  padding-top: 10px;
  padding-left: 20px;
}
.row {
  padding: 5px 20px;
  /* background: #7e1b56;
  color: #ffffff; */
  background-color: var(--primary-color);
  color: var(--primary-contrast-color);
}
.checkboxOrder {
  display: inline-flex;
  flex-direction: column;
  /* padding: 0px !important; */
}
.nodata-stat-root {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  color: #9f9f9f;
  cursor: pointer;
  height: 60px;
  text-align: center;
}
.all-table-body {
  word-wrap: break-word;
}

.title-input {
  background-color: rgba(255, 255, 255, 0.35);
  color: #222222 !important;
  padding: 0 !important;
  border: none !important;
  font-size: 1.5em !important;
  box-shadow: none;
  align-items: stretch;
  font-weight: 500;
  letter-spacing: -0.5px;
}
.drag-grid {
  display: grid !important;
  grid-template-columns: 50% 50% !important;
  column-gap: 10px !important;
  row-gap: 1em !important;
  padding: 25px;
}
.el-button--primary {
  color: var(--primary-contrast-color);
  background-color: var(--primary-color);
  &:hover {
    color: var(--primary-color);
    background-color: var(--primary-contrast-color);
    border-color: var(--primary-color);
  }
}
.wrapper-grid {
  // display: grid !important;
  // grid-template-columns: 50% 50% !important;
  // column-gap: 10px !important;
  // row-gap: 1em !important;
  // padding: 25px;
}
</style>
<style scoped>
.el-button {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  background: #fff;
  border: 1px solid #dcdfe6;
  color: #606266;
  -webkit-appearance: none;
  text-align: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  -webkit-transition: 0.1s;
  transition: 0.1s;
  font-weight: 500;
  padding: 8px 20px !important;
  font-size: 14px;
  border-radius: 4px;
}

.el-button--primary.is-disabled {
  color: #fff;
  background-color: #bebebe;
  border-color: #bebebe;
}

.custom-dashboard-view {
  overflow: hidden;
  max-height: 100vh;
  max-width: 100vw;
}

.configuration-cards {
  max-height: 95vh;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
}
.flex-container {
  display: flex;
  align-items: center;
}
.flex-item {
  margin-right: 10px; /* Adjust spacing as needed */
}
.red-asterisk {
  color: red !important;
}
.el-button--success {
  color: var(--primary-contrast-color);
  background: var(--primary-color);
  border: var(--primary-color);
}
.el-button--success.is-disabled {
  color: #fff;
  background-color: #bebebe;
  border-color: #bebebe;
}
</style>
